import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Flex, Typography, Grid } from "antd";

const { useBreakpoint } = Grid;

const primaryColor = "#ED6C02";

const App = () => {
  const screens = useBreakpoint();

  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "30px 0",
      }}
    >
      <Flex
        wrap
        gap="small"
        justify="center"
        style={{
          padding: "0px 30px",
          maxWidth: "1200px",
        }}
      >
        <Card
          hoverable
          style={{
            width: screens.lg
              ? "1035px"
              : screens.sm
              ? "700px"
              : "calc(100vw - 60px)",
            backgroundColor: "#212121",
          }}
          styles={{
            body: {
              padding: 0,
              overflow: "hidden",
            },
          }}
        >
          <Flex
            justify="space-between"
            style={{ flexDirection: screens.sm ? "row" : "column" }}
          >
            <img
              alt="avatar"
              src="./assets/welcome.jpg"
              style={{
                display: "block",
                width: screens.sm ? "300px" : "100%",
              }}
            />
            <Flex
              vertical
              align="flex-end"
              justify="space-between"
              style={{
                padding: 32,
              }}
            >
              <Typography.Title level={4} style={{ color: "white" }}>
                “Become a member today to start ordering from our extensive
                selection of high-quality construction tools and equipment at
                exclusive member pricing.”
              </Typography.Title>
              <Button
                type="primary"
                onClick={() => navigate("/register")}
                style={{ backgroundColor: primaryColor }}
              >
                Register Now
              </Button>
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </div>
  );
};
export default App;
