import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { Context } from "../context/Contexts";
import ProductCard from "./ProductCard";
import { Alert, Flex, Grid, Spin } from "antd";
import { Typography } from "@mui/material";

const { useBreakpoint } = Grid;

const API_KEY = process.env.REACT_APP_API_KEY;

const LatestProducts = () => {
  const screens = useBreakpoint();

  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const { addToCart, cartItems } = useContext(Context);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${API_KEY}/product/getLatestProducts`
        );
        setProducts(response.data);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setError(
            "Sorry, there seems to be a problem with the connection. Please try again later."
          );
        } else {
          setError("An error occurred while fetching products!");
          console.error(err);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  return (
    <>
      {isLoading && !error && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <Spin tip="Loading" />
        </div>
      )}

      {error && !isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Alert message={error} type="error" />
        </div>
      )}

      {!isLoading && !error && (
        <>
          <Typography
            component="p"
            variant="h5"
            textAlign="center"
            sx={{
              marginTop: 5,
              marginBottom: 2,
              fontWeight: 700,
            }}
          >
            Latest Products
          </Typography>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Flex
              wrap
              gap="small"
              justify="center"
              style={{
                padding: "0px 30px",
                maxWidth: "1200px",
              }}
            >
              {products.map((product, index) => (
                <ProductCard
                  key={index}
                  product={product}
                  addToCart={addToCart}
                  cartItems={cartItems}
                  screens={screens}
                />
              ))}
            </Flex>
          </div>
        </>
      )}
    </>
  );
};

export default LatestProducts;
