import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Grid, Input, theme, Typography, Modal } from "antd";
import { UserOutlined, MailOutlined } from "@ant-design/icons";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Title } = Typography;
const API_KEY = process.env.REACT_APP_API_KEY;

export default function EditProfile() {
  const { token } = useToken();
  const screens = useBreakpoint();

  const primaryColor = "#ED6C02";
  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px",
      borderRadius: "7px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
    },
    header: {
      marginBottom: token.marginXL,
      textAlign: "center",
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      border: "1px dashed red",
    },
  };

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(`${API_KEY}/user/getUser`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setUserData(response.data);
          form.setFieldsValue(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const errorMessage = error.response.data.message;
          const redirectUrl = "/editProfile";
          navigate(`/login?error=${errorMessage}&redirectUrl=${redirectUrl}`);
        }
        console.error(error);
      });
  }, [form]);

  const onFinish = (values) => {
    setShowModal(true);
  };

  const handleModalConfirm = () => {
    setLoading(true);
    setShowModal(false);

    const token = localStorage.getItem("accessToken");
    axios
      .put(`${API_KEY}/user/updateProfile`, form.getFieldsValue(), {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setUserData(response.data);
          form.setFieldsValue(response.data);
          const successMessage = response.data.message;
          navigate(`/profile?success=${successMessage}`);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const errorMessage = error.response.data.message;
          const redirectUrl = "/editProfile";
          navigate(`/login?error=${errorMessage}&redirectUrl=${redirectUrl}`);
        }
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleModalCancel = () => {
    setShowModal(false); // Hide the confirmation modal
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        {/* header */}
        <div style={styles.header}>
          <Title style={styles.title}>Edit Profile</Title>
        </div>

        {/* form */}
        <Form
          form={form}
          name="edit_profile"
          onFinish={onFinish}
          layout="vertical"
          requiredMark="optional"
          autoComplete="off"
        >
          <Form.Item
            name="FullName"
            rules={[
              {
                required: true,
                message: "Full name is required!",
              },
              {
                validator: (_, value) =>
                  value &&
                  value.trim() && // Ensure non-empty string after trimming whitespaces
                  !/^\s*$/.test(value) && // Not only whitespaces
                  /^([^ ]+ [^ ]+)+$/.test(value) // Exactly one space between words
                    ? Promise.resolve()
                    : Promise.reject(
                        "Please enter your full name with only one space between names! (Example: John Doe)"
                      ),
              },
            ]}
            hasFeedback
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="Full Name (Example: John Doe)"
            />
          </Form.Item>

          <Form.Item
            name="Email"
            rules={[
              {
                required: true,
                message: "Email address is required!",
              },
              {
                type: "email",
                message: "Please enter a valid email!",
              },
            ]}
            hasFeedback
          >
            <Input prefix={<MailOutlined />} placeholder="Email" />
          </Form.Item>

          <Form.Item
            name="Username"
            rules={[
              {
                required: true,
                message: "User Name is required",
              },
              {
                validator: (_, value) =>
                  value &&
                  value.length >= 5 &&
                  value.length <= 15 &&
                  /(?=.*[a-z])|(?=.*[A-Z])/.test(value) &&
                  /(?=.*\d)|.(?=.*[-_!])/ && // Lookahead for either number OR special character
                  !/(^\d+$|^[-_!]+$)/.test(value)
                    ? Promise.resolve()
                    : Promise.reject(
                        "Username must be 5-15 characters, include letters (uppercase or lowercase), numbers or special characters (-_.!), but not only numbers or special characters!"
                      ),
              },
            ]}
            hasFeedback
          >
            <Input prefix={<UserOutlined />} placeholder="User Name" />
          </Form.Item>

          <Form.Item>
            <Button
              onClick={() => navigate("/profile")}
              disabled={loading}
              style={{ float: "right" }}
            >
              Cancel
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
              style={{
                backgroundColor: primaryColor,
                float: "right",
                marginRight: "10px",
              }}
            >
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </div>

      {/* Confirmation Modal */}
      <Modal
        open={showModal}
        title="Confirm Changes"
        onOk={handleModalConfirm}
        onCancel={handleModalCancel}
        okText="Save Changes"
        cancelText="Cancel"
        centered // Center the modal on the screen
      >
        <p>Are you sure you want to save the changes to your profile?</p>
      </Modal>
    </section>
  );
}
