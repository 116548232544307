import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Badge } from "antd";
import { ShoppingCartOutlined, EllipsisOutlined } from "@ant-design/icons";

const { Meta } = Card;
const primaryColor = "#ED6C02";

const ProductCard = ({ product, addToCart, cartItems, screens }) => {
  const navigate = useNavigate();

  return (
    <Card
      hoverable
      key={product.id}
      style={{ width: screens.sm ? 200 : 150 }}
      cover={
        <img
          alt="example"
          src={`${process.env.REACT_APP_API_KEY}/images/${product.productImages[0].ImageName}`}
          style={{
            width: screens.sm ? "180px" : "140px",
            paddingTop: "5px",
            paddingLeft: "5px",
            height: screens.sm ? "180px" : "140px",
          }}
          onClick={() => navigate(`/product/detail/${product.id}`)}
        />
      }
      actions={[
        <Badge
          count={cartItems[product.id]}
          overflowCount={99}
          color={primaryColor}
        >
          <Button
            type="text"
            icon={<ShoppingCartOutlined />}
            onClick={() => addToCart(product.id)}
          />
        </Badge>,
        <Button
          type="text"
          icon={<EllipsisOutlined />}
          onClick={() => navigate(`/product/detail/${product.id}`)}
        />,
      ]}
    >
      <Meta
        title={product.Name}
        description={product.Price + " ETB"}
        onClick={() => navigate(`/product/detail/${product.id}`)}
      />
    </Card>
  );
};

export default ProductCard;
