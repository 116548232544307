export default [
  {
    title: "ጥራት ያላቸው እቃዎች",
    text: "ጥራቱ የተረጋገጠ እና ኣስተማማኝ የሆኑ ኦሪጂናል ኢቃዎች",
  },
  {
    title: "ፈጣን አና ቀላል ግብይት",
    text: "የፈለጉትን እቃ በቶሎ እናደርሳለን",
  },
  {
    title: "ሁልጊዜም ተመራጭ",
    text: "",
  },
];
