import React, { useContext } from "react";
import { Context } from "../context/Contexts";
import AspectRatio from "@mui/joy/AspectRatio";
import Link from "@mui/joy/Link";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Chip from "@mui/joy/Chip";
import Typography from "@mui/joy/Typography";
import { Button, Input } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";

const API_KEY = process.env.REACT_APP_API_KEY;

export default function CartItem({ product, index }) {
  const navigate = useNavigate();
  const { cartItems, addToCart, subtractFromCart, updateCartItemAmount } =
    useContext(Context);

  return (
    <>
      <Card
        key={index}
        variant="outlined"
        orientation="horizontal"
        color="warning"
        sx={{
          marginBottom: "10px",
          border: "0",
          width: "95%",
          "&:hover": {
            boxShadow: "xl",
            backgroundColor: "#eaeaea",
            borderColor: "orange",
          },
        }}
      >
        <AspectRatio ratio="1" sx={{ width: 90 }}>
          <img
            src={`${API_KEY}/images/${product.productImages[0].ImageName}?auto=format&fit=crop&w=90`}
            srcSet={`${API_KEY}/images/${product.productImages[0].ImageName}?auto=format&fit=crop&w=90&dpr=2 2x`}
            loading="lazy"
            alt=""
          />
        </AspectRatio>

        <CardContent>
          <Typography level="title-lg" id="card-description">
            {product.Name}
          </Typography>

          <Typography
            level="body-sm"
            aria-describedby="card-description"
            mb={1}
          >
            <Link
              overlay
              underline="none"
              onClick={() => navigate(`/product/detail/${product.id}`)}
              sx={{ color: "text.tertiary" }}
            >
              {product.Price} ETB
            </Link>
          </Typography>

          <Chip
            variant="outlined"
            color="primary"
            size="sm"
            sx={{ pointerEvents: "none" }}
          >
            {product.Category}
          </Chip>
        </CardContent>

        <CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Button
              style={{
                backgroundColor: "#ED6C02",
                borderRadius: 0,
                borderTopLeftRadius: "5px",
                borderBottomLeftRadius: "5px",
              }}
              onClick={() => subtractFromCart(product.id)}
            >
              -
            </Button>

            <Input
              variant="outlined"
              color="warning"
              value={cartItems[product.id]}
              onChange={(e) =>
                updateCartItemAmount(product.id, Number(e.target.value))
              }
              sx={{ width: "60px", borderRadius: 0, textAlign: "center" }}
            />

            <Button
              style={{
                backgroundColor: "#ED6C02",
                borderRadius: 0,
                borderTopRightRadius: "5px",
                borderBottomRightRadius: "5px",
              }}
              onClick={() => addToCart(product.id)}
            >
              +
            </Button>
          </div>
        </CardContent>
      </Card>
      <Divider />
    </>
  );
}
