import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Typography, theme, Modal, Alert } from "antd";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Title, Text } = Typography;
const API_KEY = process.env.REACT_APP_API_KEY;

export default function UserProfilePage() {
  axios.defaults.withCredentials = true;

  const { token } = useToken();
  const screens = useBreakpoint();

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px",
      borderRadius: "7px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
    header: {
      marginBottom: token.marginXL,
      textAlign: "center",
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
    fields: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "10px",
    },
    label: {
      fontSize: "13px",
      marginBottom: "0px",
    },
    info: {
      fontSize: "17px",
      fontWeight: 400,
    },
  };

  const [showModal, setShowModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [userData, setUserData] = useState({
    FullName: "",
    Email: "",
    Username: "",
    Role: "",
    isBanned: "",
    isDeleted: "",
  });

  const navigate = useNavigate();
  let { id } = useParams();
  const userId = id;

  useEffect(() => {
    const fetchUserData = () => {
      const token = localStorage.getItem("accessToken");
      axios
        .get(`${API_KEY}/user/getUser/${userId}`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setUserData(response.data);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            const errorMessage = error.response.data.message;
            const redirectUrl = "/profile";
            navigate(`/login?error=${errorMessage}&redirectUrl=${redirectUrl}`);
          }
          console.error(error);
        });
    };

    fetchUserData();
  }, [userId]);

  const handleRestoreConfirm = async () => {
    const token = localStorage.getItem("accessToken");
    await axios
      .put(`${API_KEY}/user/restoreAccount/${userId}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setUserData((prevUserData) => ({
            ...prevUserData,
            isDeleted: false,
          }));
          setShowRestoreModal(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const errorMessage = error.response.data.message;
          const redirectUrl = "/profile/" + userId;
          navigate(`/login?error=${errorMessage}&redirectUrl=${redirectUrl}`);
        } else if (error.response.status === 403) {
          navigate("/notAuthorized");
        } else if (error.response.status === 404) {
          console.log(error.response);
          navigate("/profile/" + userId);
        }

        console.error(error);
      });
  };

  const handleBanConfirm = async () => {
    const token = localStorage.getItem("accessToken");
    await axios
      .put(
        `${API_KEY}/user/banUnban/${userId}`,
        {},
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setUserData((prevUserData) => ({
            ...prevUserData,
            isBanned: !prevUserData.isBanned,
          }));
          setShowModal(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const errorMessage = error.response.data.message;
          const redirectUrl = "/profile/" + userId;
          navigate(`/login?error=${errorMessage}&redirectUrl=${redirectUrl}`);
        } else if (error.response.status === 403) {
          navigate("/notAuthorized");
        } else if (error.response.status === 404) {
          console.log(error.response);
          navigate("/profile/" + userId);
        }

        console.error(error);
      });
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        {/* header */}
        <div style={styles.header}>
          <Title style={styles.title}>User Profile</Title>
        </div>

        <div style={styles.fields}>
          <Text type="secondary" style={styles.label}>
            Full Name
          </Text>
          <Text style={styles.info}>{userData.FullName}</Text>
        </div>

        <div style={styles.fields}>
          <Text type="secondary" style={styles.label}>
            Email
          </Text>
          <Text style={styles.info}>{userData.Email}</Text>
        </div>

        <div style={styles.fields}>
          <Text type="secondary" style={styles.label}>
            Username
          </Text>
          <Text style={styles.info}>{userData.Username}</Text>
        </div>

        {userData.isDeleted === true && (
          <Button
            type="primary"
            style={{
              backgroundColor: "#ffbf00",
              marginTop: "10px",
              float: "right",
            }}
            onClick={() => setShowRestoreModal(true)}
          >
            Restore Account
          </Button>
        )}

        {userData.Role === "Client" && (
          <Button
            type="primary"
            style={{
              backgroundColor: userData.isBanned ? "#6fc276" : "#d92525",
              marginTop: "10px",
              marginRight: "10px",
              float: "right",
            }}
            onClick={() => setShowModal(true)}
          >
            {userData.isBanned ? "Remove Ban" : "Ban Account"}
          </Button>
        )}

        <Modal
          open={showRestoreModal}
          title="Confirm Account Restoration"
          onOk={handleRestoreConfirm}
          onCancel={() => setShowRestoreModal(false)}
          okText="Yes"
          cancelText="No"
          okButtonProps={{ danger: true }}
          centered
        >
          <p>Are you sure you want to restore this account?</p>
        </Modal>

        <Modal
          open={showModal}
          title={
            userData.isBanned ? "Confirm Account Unban" : "Confirm Account Ban"
          }
          onOk={handleBanConfirm}
          onCancel={() => setShowModal(false)}
          okText="Yes"
          cancelText="No"
          okButtonProps={{ danger: true }}
          centered
        >
          <p>
            Are you sure you want to
            {userData.isBanned ? " remove the ban from" : " ban"} this account?
          </p>
        </Modal>
      </div>
    </section>
  );
}
