import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { Context } from "../context/Contexts";
import ProductCard from "./ProductCard";
import { Alert, Empty, Flex, Grid, Pagination, Spin } from "antd";
import { Typography } from "@mui/material";

const { useBreakpoint } = Grid;

const API_KEY = process.env.REACT_APP_API_KEY;

const FeaturedProducts = () => {
  const screens = useBreakpoint();

  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [productsPerPage, setProductsPerPage] = useState(10);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const { addToCart, cartItems, searchTerm } = useContext(Context);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${API_KEY}/product/getProducts`);
        setProducts(response.data);
        setDisplayedProducts(response.data.slice(0, productsPerPage));
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setError(
            "Sorry, there seems to be a problem with the connection. Please try again later."
          );
        } else {
          setError("An error occurred while fetching products!");
          console.error(err);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const result = products.filter((product) =>
      product.Name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(result);
    setDisplayedProducts(result.slice(0, productsPerPage));
  }, [products, searchTerm, productsPerPage]);

  const handlePageChange = (page) => {
    const startIndex = (page - 1) * productsPerPage;
    const endIndex = startIndex + productsPerPage;
    // setDisplayedProducts(products.slice(startIndex, endIndex));
    !searchTerm
      ? setDisplayedProducts(products.slice(startIndex, endIndex))
      : setDisplayedProducts(filteredProducts.slice(startIndex, endIndex));
    setCurrentPage(page);
  };

  return (
    <>
      {isLoading && !error && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <Spin tip="Loading" />
        </div>
      )}

      {error && !isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Alert message={error} type="error" />
        </div>
      )}

      {!isLoading && !error && (
        <>
          <Typography
            component="p"
            variant="h5"
            textAlign="center"
            sx={{
              marginTop: 5,
              marginBottom: 2,
              fontWeight: 700,
            }}
          >
            Featured Products
          </Typography>

          {filteredProducts.length === 0 && searchTerm ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "20rem",
              }}
            >
              <Empty
                description={`No results found for "${searchTerm}"`}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Flex
                wrap
                gap="small"
                justify="center"
                style={{
                  padding: "0px 30px",
                  maxWidth: "1200px",
                }}
              >
                {displayedProducts.map((product, index) => (
                  <ProductCard
                    key={index}
                    product={product}
                    addToCart={addToCart}
                    cartItems={cartItems}
                    screens={screens}
                  />
                ))}
              </Flex>
            </div>
          )}

          {/* Pagination */}
          {displayedProducts.length !== 0 && (
            <Pagination
              current={currentPage} // Set current page from state
              total={!searchTerm ? products.length : filteredProducts.length} // Use total number of products
              pageSize={productsPerPage} // Adjust page size as needed
              onChange={handlePageChange}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "5px",
                marginBottom: "40px",
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default FeaturedProducts;
