import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../context/Contexts";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ConstructionIcon from "@mui/icons-material/Construction";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import CategoryRoundedIcon from "@mui/icons-material/CategoryRounded";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Divider } from "@mui/material";
import { Modal } from "antd";

const API_KEY = process.env.REACT_APP_API_KEY;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function Header() {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const {
    authState,
    setAuthState,
    authRole,
    setAuthRole,
    loggedInUsername,
    setLoggedInUsername,
    cartItems,
    searchTerm,
    setSearchTerm,
  } = useContext(Context);

  const navigate = useNavigate();

  // checks if the user is logged in or not (authenticaiton)
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(API_KEY + "/auth/checkAuthentication", {
        withCredentials: true,
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAuthState(true);
          setAuthRole(response.data.role);
          // setLoggedInUsername(response.data.username);
        } else {
          setAuthState(false);
          setAuthRole("");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setAuthState(false);
          setAuthRole("");
        }
      });
  }, []);

  // fetch all products
  useEffect(() => {
    const fetchProducts = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await axios.get(`${API_KEY}/product/getProducts`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        setProducts(response.data);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          console.error("No products found!");
        } else {
          console.error("An error occurred while fetching products!");
        }
      }
    };

    fetchProducts();
  }, []);

  const handleLogout = async () => {
    setShowModal(false);
    const token = localStorage.getItem("accessToken");

    axios
      .get(API_KEY + "/auth/logout", {
        withCredentials: true,
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAuthState(false);
          setAuthRole("");
          setLoggedInUsername("");
          sessionStorage.clear();
          localStorage.removeItem("accessToken");
          navigate("/login");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCartQuantity = () => {
    let totalQuantity = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        const itemInfo = products.find((product) => product.id == Number(item));
        if (itemInfo) {
          totalQuantity += cartItems[item];
        }
      }
    }
    return totalQuantity;
  };
  let totalQuantity = getCartQuantity();

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {authState && (
        <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem>
      )}
      {authState && (
        <MenuItem onClick={() => setShowModal(true)}>Log Out</MenuItem>
      )}
      {!authState && (
        <MenuItem onClick={() => navigate("/login")}>Login</MenuItem>
      )}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => navigate("/")}>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <HomeRoundedIcon />
        </IconButton>
        <p>Home</p>
      </MenuItem>

      {authState && authRole === "Admin" && (
        <MenuItem onClick={() => navigate("/dashboard")}>
          <IconButton size="large" color="inherit">
            <DashboardCustomizeIcon />
          </IconButton>
          <p>Dashboard</p>
        </MenuItem>
      )}

      <MenuItem onClick={() => navigate("/product")}>
        <IconButton
          size="large"
          // aria-label="show 17 new notifications"
          color="inherit"
        >
          <ConstructionIcon />
        </IconButton>
        <p>Products</p>
      </MenuItem>

      <MenuItem onClick={() => navigate("/category")}>
        <IconButton
          size="large"
          // aria-label="Category of products"
          color="inherit"
        >
          <CategoryRoundedIcon />
        </IconButton>
        <p>Category</p>
      </MenuItem>

      <Divider />

      <MenuItem onClick={() => navigate("/cart")}>
        <IconButton
          size="large"
          // aria-label={`${totalQuantity} items available in cart`}
          color="inherit"
        >
          <Badge badgeContent={totalQuantity} color="warning">
            {/* <Badge badgeContent={3} color="warning"> */}
            <ShoppingCartRoundedIcon />
          </Badge>
        </IconButton>
        <p>Cart</p>
      </MenuItem>

      {authState && (
        <MenuItem onClick={() => navigate("/profile")}>
          <IconButton size="large" color="inherit">
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      )}

      {authState && (
        <MenuItem onClick={() => setShowModal(true)}>
          <IconButton size="large" color="inherit">
            <LogoutIcon />
          </IconButton>
          <p>Logout</p>
        </MenuItem>
      )}

      {!authState && (
        <MenuItem onClick={() => navigate("/login")}>
          <IconButton size="large" color="inherit">
            <LoginIcon />
          </IconButton>
          <p>Login</p>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{ backgroundColor: "#212121", color: "white" }}
      >
        <Toolbar>
          {/* logo */}
          <IconButton
            size="large"
            edge="start"
            color="warning"
            aria-label="open drawer"
            onClick={() => navigate("/")}
          >
            <img
              src="./Betseri_Icon192.png"
              alt="Beteseri Logo"
              style={{ width: "35px", height: "35px" }}
            />
          </IconButton>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block", cursor: "pointer" } }}
            onClick={() => navigate("/")}
          >
            BETESERI
          </Typography>

          <Box sx={{ flexGrow: 1 }} />

          {/* Links */}
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Typography
              variant="p"
              noWrap
              component="div"
              sx={{ mr: 5, cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              Home
            </Typography>

            {authState && authRole === "Admin" && (
              <Typography
                variant="p"
                noWrap
                component="div"
                sx={{ mr: 5, cursor: "pointer" }}
                onClick={() => navigate("/dashboard")}
              >
                Dashboard
              </Typography>
            )}

            <Typography
              variant="p"
              noWrap
              component="div"
              sx={{ mr: 5, cursor: "pointer" }}
              onClick={() => navigate("/product")}
            >
              Products
            </Typography>

            <Typography
              variant="p"
              noWrap
              component="div"
              sx={{ mr: 5, cursor: "pointer" }}
              onClick={() => navigate("/category")}
            >
              Categories
            </Typography>
          </Box>

          {/* Search Bar */}
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search product…"
              inputProps={{ "aria-label": "search" }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Search>

          {/* Buttons */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
              onClick={() => navigate("/cart")}
            >
              <Badge badgeContent={totalQuantity} color="warning">
                <ShoppingCartRoundedIcon />
              </Badge>
            </IconButton>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              // border: "1px dashed red",
            }}
          >
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
              <p style={{ margin: 0, fontSize: "14px", marginLeft: 3 }}>
                {loggedInUsername}
              </p>
            </IconButton>
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}

      {/* Confirmation Modal */}
      <Modal
        open={showModal}
        title="Confirm Logout"
        onOk={handleLogout}
        onCancel={() => setShowModal(false)}
        okText="Log Out"
        cancelText="Cancel"
        centered
      >
        <p>Are you sure you want to log out?</p>
      </Modal>
    </Box>
  );
}
