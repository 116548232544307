import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Alert,
  Button,
  Checkbox,
  Form,
  Grid,
  Input,
  theme,
  Typography,
} from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Context } from "../../context/Contexts";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;
const API_KEY = process.env.REACT_APP_API_KEY;

export default function Login() {
  axios.defaults.withCredentials = true;
  const { token } = useToken();
  const screens = useBreakpoint();

  const primaryColor = "#ED6C02";
  const styles = {
    container: {
      margin: "auto auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px",
      borderRadius: "7px",
      boxShadow: screens.sm ? "rgba(149, 157, 165, 0.2) 0px 8px 24px" : "",
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
      color: primaryColor,
    },
    header: {
      marginBottom: token.marginXL,
      textAlign: "center",
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "90vh",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
      margin: screens.sm ? "0px" : "30px",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
  };

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const navigate = useNavigate();
  const { setAuthState, setAuthRole, setLoggedInUsername } =
    useContext(Context);
  const [searchParams] = useSearchParams();
  const errorMessage = searchParams.get("error");
  const redirectUrl = searchParams.get("redirectUrl");

  // Route to home if already logged in
  useEffect(() => {
    if (errorMessage) setError(errorMessage);
    const checkAuth = () => {
      const token = localStorage.getItem("accessToken");
      axios
        .get(API_KEY + "/auth/checkAuthentication", {
          withCredentials: true,
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            navigate("/");
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            console.log(error.response.data.message);
          }
        });
    };

    checkAuth();
  }, []);

  const onFinish = (values) => {
    setIsLoggingIn(true);
    axios
      .post(API_KEY + "/auth/login", values)
      .then((response) => {
        if (response.status === 200) {
          setError(null);
          setSuccess(response.data.message + " Redirecting...");

          setAuthState(true);
          setAuthRole(response.data.role);
          setLoggedInUsername(response.data.username);

          localStorage.setItem("accessToken", response.data.token);

          redirectUrl ? navigate(redirectUrl) : navigate("/");
          setSuccess(null);
        }
      })
      .catch((error) => {
        setSuccess(null);
        setError(error?.response?.data?.message);
      })
      .finally(() => {
        setIsLoggingIn(false);
      });
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        {/* header */}
        <div style={styles.header}>
          <Title style={styles.title}>Login</Title>
          {error && !success && <Alert message={error} type="error" showIcon />}
          {success && !error && (
            <Alert message={success} type="success" showIcon />
          )}
          {!success && !error && (
            <Text style={styles.text}>
              Welcome back to Beteseri! Please enter your details below to sign
              in.
            </Text>
          )}
        </div>

        {/* form */}
        <Form
          name="user_login"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
          layout="vertical"
          requiredMark="optional"
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "User Name is required",
              },
              {
                validator: (_, value) =>
                  value &&
                  !/\s/.test(value) &&
                  value.length >= 5 &&
                  value.length <= 15
                    ? Promise.resolve()
                    : Promise.reject(
                        "Username must be 5 - 15 characters and contain no spaces!"
                      ),
              },
            ]}
            hasFeedback
          >
            <Input
              prefix={<UserOutlined />}
              type="text"
              placeholder="User Name"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Password is required!",
              },
              {
                validator: (_, value) =>
                  value &&
                  !/\s/.test(value) &&
                  value.length >= 8 &&
                  value.length <= 12
                    ? Promise.resolve()
                    : Promise.reject(
                        "Password must be 8 - 12 characters and contain no spaces!"
                      ),
              },
            ]}
            hasFeedback
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Link
              style={styles.forgotPassword}
              onClick={() => navigate("/sendEmail")}
            >
              Forgot password?
            </Link>
          </Form.Item>

          <Form.Item style={{ marginBottom: "0px" }}>
            <Button
              block="true"
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: primaryColor }}
              disabled={isLoggingIn}
            >
              {isLoggingIn ? "Logging in..." : "Log in"}
            </Button>

            <div style={styles.footer}>
              <Text style={styles.text}>Don't have an account?</Text>{" "}
              <Link
                onClick={() => navigate("/register")}
                style={{ color: primaryColor }}
              >
                Sign up now
              </Link>
            </div>
          </Form.Item>
        </Form>
      </div>
    </section>
  );
}
