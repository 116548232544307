import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Alert, Breadcrumb, Button, Flex, Spin, Table, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const API_KEY = process.env.REACT_APP_API_KEY;

const columns = [
  {
    title: "id",
    dataIndex: "id",
    defaultSortOrder: "ascend",
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: "Product ID",
    dataIndex: "productId",
    key: "productId",
  },
  {
    title: "Product Name",
    dataIndex: "productName",
    key: "fullname",
    render: (text, record) => (
      <Link to={`/product/detail/${record.id}`}>{text}</Link>
    ),
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
  },
  {
    title: "Unit Price",
    dataIndex: "unitPrice",
    defaultSortOrder: "decend",
    sorter: (a, b) => a.unitPrice - b.unitPrice,
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    defaultSortOrder: "decend",
    sorter: (a, b) => a.quantity - b.quantity,
  },
  {
    title: "Units Per Pack",
    dataIndex: "unitsPerPack",
    defaultSortOrder: "decend",
    sorter: (a, b) => a.unitsPerPack - b.unitsPerPack,
  },
  {
    title: "Minimum Order",
    dataIndex: "minimumOrder",
    defaultSortOrder: "decend",
    sorter: (a, b) => a.minimumOrder - b.minimumOrder,
  },
  {
    title: "Deleted Status",
    dataIndex: "isDeleted",
    key: "isDeleted",
    render: (_, { isDeleted }) => (
      <>
        {isDeleted === true ? (
          <Tag color="red">Deleted</Tag>
        ) : (
          <Tag color="green">Active</Tag>
        )}
      </>
    ),
  },
];

const ProductList = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await axios.get(`${API_KEY}/product/getAllProducts`, {
          withCredentials: true,
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        const transformedData = response.data.map((product, index) => ({
          key: index,
          id: product.id,
          productId: product.ProductId,
          productName: product.Name,
          category: product.Category,
          unitPrice: product.Price,
          quantity: product.Quantity,
          unitsPerPack: product.Units_per_Pack,
          minimumOrder: product.MinimumOrder,
          isDeleted: product.isDeleted,
        }));

        setData(transformedData);
      } catch (error) {
        if (error.response.status === 404) {
          setError(
            "Sorry, there seems to be a problem while fetching the products data. Please try again later."
          );
        } else if (error.response.status === 401) {
          const errorMessage = error.response.data.message;
          const redirectUrl = "/dashboard";
          navigate(`/login?error=${errorMessage}&redirectUrl=${redirectUrl}`);
        } else if (error.response.status === 403) {
          navigate("/notAuthorized");
        } else {
          setError("An error occurred while fetching products!");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [navigate]);

  return (
    <>
      <Flex justify="space-between" align="center">
        <div>
          <h1>Product List</h1>

          <Breadcrumb
            style={{
              margin: "0 0 30px 5px",
            }}
          >
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item>Product List</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div>
          <Button
            icon={<PlusOutlined />}
            onClick={() => navigate("/addProduct")}
          >
            Add Product
          </Button>
        </div>
      </Flex>

      <div>
        {isLoading && !error && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            <Spin tip="Loading" />
          </div>
        )}

        {error && !isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Alert message={error} type="error" />
          </div>
        )}

        {!isLoading && !error && (
          <Table
            style={{ padding: "0px" }}
            columns={columns}
            dataSource={data}
            showSorterTooltip={{
              target: "sorter-icon",
            }}
          />
        )}
      </div>
    </>
  );
};

export default ProductList;
