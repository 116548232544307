import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Typography, theme, Modal, Carousel, Badge } from "antd";
import { Context } from "../../context/Contexts";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Title, Text } = Typography;
const API_KEY = process.env.REACT_APP_API_KEY;

export default function ViewProfile() {
  axios.defaults.withCredentials = true;

  const { token } = useToken();
  const screens = useBreakpoint();

  const primaryColor = "#ED6C02";
  const styles = {
    container: {
      margin: "auto auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "580px",
      // maxWidth: "580px",
      borderRadius: "7px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
    header: {
      marginBottom: token.marginXL,
      textAlign: "center",
    },
    section: {
      position: "relative",
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
    fields: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "10px",
    },
    label: {
      fontSize: "13px",
      marginBottom: "0px",
    },
    info: {
      fontSize: "17px",
      fontWeight: 400,
    },
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [productData, setProductData] = useState({
    ProductId: "",
    Name: "",
    Description: "",
    Category: "",
    Price: "",
    Quantity: "",
    Unit: "",
    Units_per_Pack: "",
    MinimumOrder: "",
    isDeleted: "",
    productImages: [],
  });
  const [userRole, setUserRole] = useState("");

  const { addToCart } = useContext(Context);
  const navigate = useNavigate();
  let { id } = useParams();
  const productId = id;

  // Getting user role (for button display)
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(API_KEY + "/auth/getRole", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setUserRole(response.data.role);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setUserRole("Unknown");
        }
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (productId) {
      axios
        .get(API_KEY + `/product/getProduct/${productId}`)
        .then((response) => {
          if (response.status === 200) {
            setProductData(response.data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [productId]);

  const handleDeleteModalConfirm = async () => {
    if (productId) {
      const token = localStorage.getItem("accessToken");
      axios
        .delete(API_KEY + `/product/delete/${productId}`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            navigate("/product");
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            const errorMessage = error.response.data.message;
            const redirectUrl = `/product/detail/${productId}`;
            navigate(`/login?error=${errorMessage}&redirectUrl=${redirectUrl}`);
          } else if (error.response.status === 403) {
            navigate("/notAuthorized");
          }
          console.error(error);
        });
    }
  };

  const handleRestoreModalConfirm = async () => {
    if (productId) {
      const token = localStorage.getItem("accessToken");
      axios
        .put(
          API_KEY + `/product/restore/${productId}`,
          {},
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            navigate("/product");
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            const errorMessage = error.response.data.message;
            const redirectUrl = `/product/detail/${productId}`;
            navigate(`/login?error=${errorMessage}&redirectUrl=${redirectUrl}`);
          } else if (error.response.status === 403) {
            navigate("/notAuthorized");
          }
          console.error(error);
        });
    }
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        {/* header */}
        {productData.isDeleted && (
          <Badge.Ribbon text="Product is Deleted" color="red"></Badge.Ribbon>
        )}
        <div style={styles.header}>
          <Title style={styles.title}>Product Information</Title>
        </div>

        {/* Carousel */}
        {productData.productImages && productData.productImages.length > 0 && (
          <Carousel
            autoplay
            arrows
            style={{
              width: "70%",
              maxWidth: "300px",
              margin: "0 auto",
            }}
            id="carousel"
          >
            {productData.productImages.map((image) => (
              <img
                key={image.id}
                src={`${API_KEY}/images/${image.ImageName}`}
                alt={image.ImageName}
                style={{}}
              />
            ))}
          </Carousel>
        )}

        {/* Fields */}
        <div style={styles.fields}>
          <Text type="secondary" style={styles.label}>
            Product Id
          </Text>
          <Text style={styles.info}>{productData.ProductId}</Text>
        </div>

        <div style={styles.fields}>
          <Text type="secondary" style={styles.label}>
            Product Name
          </Text>
          <Text style={styles.info}>{productData.Name}</Text>
        </div>

        <div style={styles.fields}>
          <Text type="secondary" style={styles.label}>
            Description
          </Text>
          <Text style={styles.info}>{productData.Description}</Text>
        </div>

        <div style={styles.fields}>
          <Text type="secondary" style={styles.label}>
            Category
          </Text>
          <Text style={styles.info}>{productData.Category}</Text>
        </div>

        <div style={styles.fields}>
          <Text type="secondary" style={styles.label}>
            Price
          </Text>
          <Text style={styles.info}>{productData.Price} ETB</Text>
        </div>

        <div style={styles.fields}>
          <Text type="secondary" style={styles.label}>
            Units Per Pack
          </Text>
          <Text style={styles.info}>
            {productData.Units_per_Pack} {productData.Unit}
          </Text>
        </div>

        {/* Buttons */}
        {userRole !== "Admin" &&
          userRole !== "Client" &&
          productData.isDeleted === false && (
            <Button
              type="primary"
              style={{
                marginTop: "10px",
                float: "right",
                backgroundColor: "#212121",
                color: "white",
              }}
              onClick={() => navigate("/product")}
            >
              Continue Shopping
            </Button>
          )}

        {userRole !== "Admin" &&
          userRole !== "Client" &&
          productData.isDeleted === false && (
            <Button
              type="primary"
              style={{
                marginTop: "10px",
                marginRight: "10px",
                float: "right",
                backgroundColor: primaryColor,
              }}
              onClick={() => addToCart(productData.id)}
            >
              Add to Cart
            </Button>
          )}

        {userRole === "Client" && productData.isDeleted === false && (
          <Button
            type="primary"
            style={{
              marginTop: "10px",
              float: "right",
              backgroundColor: "#212121",
            }}
            onClick={() => navigate("/product")}
          >
            Continue Shopping
          </Button>
        )}

        {userRole === "Client" && productData.isDeleted === false && (
          <Button
            type="primary"
            style={{
              marginTop: "10px",
              marginRight: "10px",
              float: "right",
              backgroundColor: primaryColor,
            }}
            onClick={() => addToCart(productData.id)}
          >
            Add to Cart
          </Button>
        )}

        {userRole === "Admin" && productData.isDeleted === false && (
          <Button
            type="primary"
            danger
            style={{ marginTop: "10px", float: "right" }}
            onClick={() => setShowDeleteModal(true)}
          >
            Delete Product
          </Button>
        )}

        {userRole === "Admin" && productData.isDeleted === false && (
          <Button
            type="primary"
            style={{
              backgroundColor: primaryColor,
              marginTop: "10px",
              marginRight: "10px",
              float: "right",
            }}
            onClick={() => navigate(`/editProduct/${productData.id}`)}
          >
            Edit Product
          </Button>
        )}

        {userRole === "Admin" && productData.isDeleted === true && (
          <Button
            type="primary"
            style={{
              backgroundColor: "#ffbf00",
              marginTop: "10px",
              float: "right",
            }}
            onClick={() => setShowRestoreModal(true)}
          >
            Restore Product
          </Button>
        )}

        {/* Modals */}
        <Modal
          open={showDeleteModal}
          title="Confirm Product Deletion"
          onOk={handleDeleteModalConfirm}
          onCancel={() => setShowDeleteModal(false)}
          okText="Delete"
          okButtonProps={{ danger: true }}
          cancelText="Cancel"
          centered
        >
          <p>
            Are you sure you want to delete this product?
            <span style={{ fontStyle: "italic", fontSize: 12 }}>
              (This action will remove the product from the product listing)
            </span>
          </p>
        </Modal>

        <Modal
          open={showRestoreModal}
          title="Confirm Product Restoration"
          onOk={handleRestoreModalConfirm}
          onCancel={() => setShowRestoreModal(false)}
          okText="Restore"
          okButtonProps={{ danger: true }}
          cancelText="Cancel"
          centered
        >
          <p>
            Are you sure you want to restore this deleted product?
            <span style={{ fontStyle: "italic", fontSize: 12 }}>
              (This action will make the product visible in the product listing
              again)
            </span>
          </p>
        </Modal>
      </div>
    </section>
  );
}
