import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Form,
  Grid,
  Input,
  Modal,
  theme,
  Typography,
} from "antd";
import { LockOutlined } from "@ant-design/icons";
// import { ProductContext } from "../context/ProductContext";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;
const API_KEY = process.env.REACT_APP_API_KEY;

export default function ChangePassword() {
  //   axios.defaults.withCredentials = true;

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const { token } = useToken();
  const screens = useBreakpoint();

  const navigate = useNavigate();

  const primaryColor = "#ED6C02";
  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px",
      borderRadius: "7px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
    header: {
      marginBottom: token.marginXL,
      textAlign: "center",
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
  };

  const onFinish = (values) => {
    setFormData(values);
    setShowModal(true);
  };

  const handleModalConfirm = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .put(
        API_KEY + "/user/changePassword",
        {
          oldPassword: formData.oldPassword,
          newPassword: formData.newPassword,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setError(null);
          setSuccess(response.data.message);

          navigate("/profile");

          setSuccess(null);
        }
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 401) {
          handleModalCancel();
          setError(error.response.data.message);
        }
        console.error(error);
        setError(error?.response?.data?.message);
      });
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        {/* header */}
        <div style={styles.header}>
          <Title style={styles.title}>Change Password</Title>
          {error && !success && <Alert message={error} type="error" showIcon />}
          {success && !error && (
            <Alert message={success} type="success" showIcon />
          )}
          {!success && !error && (
            <Text style={styles.text}>
              Please enter your old password then new password.
            </Text>
          )}
        </div>

        {/* form */}
        <Form
          name="change_password"
          onFinish={onFinish}
          layout="vertical"
          requiredMark="optional"
        >
          <Form.Item
            name="oldPassword"
            rules={[
              {
                required: true,
                message: "Old Password is required!",
              },
              {
                validator: (_, value) =>
                  value &&
                  !/\s/.test(value) &&
                  value.length >= 8 &&
                  value.length <= 12
                    ? Promise.resolve()
                    : Promise.reject(
                        "Password must be 8 - 12 characters long and contain no spaces!"
                      ),
              },
            ]}
            hasFeedback
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Old Password"
            />
          </Form.Item>

          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: "New Password is required!",
              },
              {
                validator: (_, value) =>
                  value &&
                  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]/.test(value) &&
                  value.length >= 8 &&
                  value.length <= 12
                    ? Promise.resolve()
                    : Promise.reject(
                        "Password must be 8 - 12 characters long and contain a lowercase letter, an uppercase letter, and a number!"
                      ),
              },
            ]}
            hasFeedback
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="New Password"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={["newPassword"]}
            rules={[
              {
                required: true,
                message: "Confirm Password is required!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Passwords do not match!");
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Confirm Password"
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: "0px" }}>
            <Button
              onClick={() => navigate("/profile")}
              style={{ float: "right" }}
            >
              Cancel
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: primaryColor,
                float: "right",
                marginRight: "10px",
              }}
            >
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </div>

      <Modal
        open={showModal}
        title="Confirm Password Change"
        onOk={handleModalConfirm}
        onCancel={handleModalCancel}
        okText="Change Password"
        cancelText="Cancel"
        centered
      >
        <p>Are you sure you want to change to your password?</p>
      </Modal>
    </section>
  );
}
