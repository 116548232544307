import React, { useEffect, useState } from "react";

const Footer = () => {
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        height: "64px",
        backgroundColor: "#212121",
      }}
    >
      <p>&copy; {year} Beteseri. All rights reserved.</p>
    </div>
  );
};

export default Footer;
