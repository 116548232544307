import { useContext } from "react";
import { Context } from "../../context/Contexts";
import Banner from "../../components/Banner";
import LatestProducts from "../../components/LatestProducts";
import LoginBanner from "../../components/loginBanner";
import DiscountBanner from "../../components/DiscountBanner";
import FeaturedProducts from "../../components/FeaturedProducts";
import Footer from "../../components/Footer";

const ProudctPage = () => {
  const { authState, searchTerm } = useContext(Context);

  return (
    <div>
      <Banner />

      {!searchTerm && <LatestProducts />}

      {!authState && !searchTerm && <LoginBanner />}

      {!searchTerm && authState && <DiscountBanner />}

      <FeaturedProducts />

      {!searchTerm && <Footer />}
    </div>
  );
};

export default ProudctPage;
