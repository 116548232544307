export const categories = [
  { value: "Concrete & Masonry Works", label: "Concrete & Masonry Works" },
  { value: "Formwork", label: "Formwork" },
  { value: "Reinforcement Bar", label: "Reinforcement Bar" },
  { value: "Steel Structure", label: "Steel Structure" },
  { value: "Metal Workers", label: "Metal Workers" },
  { value: "Carpentary and Joinery", label: "Carpentary and Joinery" },
  { value: "Wall Finish", label: "Wall Finish" },
  { value: "Floor Finish", label: "Floor Finish" },
  { value: "Glazing Works", label: "Glazing Works" },
  { value: "Painting", label: "Painting" },
  { value: "Sanitary Work", label: "Sanitary Work" },
  { value: "Electrical Work", label: "Electrical Work" },
  { value: "Hollow Concrete Block", label: "Hollow Concrete Block" },
  { value: "Site Work", label: "Site Work" },
  { value: "Almunium Materials", label: "Aluminum Materials" },
  { value: "Admixture and Chemicals", label: "Admixture and Chemicals" },
  { value: "Water Proofing", label: "Water Proofing" },
  { value: "Electro Mechanical", label: "Electro Mechanical" },
  { value: "Scaffolding", label: "Scaffolding" },
  { value: "Tools", label: "Tools" },
  { value: "Equipment", label: "Equipment" },
  { value: "Machine", label: "Machine" },
  { value: "Safety", label: "Safety" },
  { value: "Ceiling", label: "Ceiling" },
  { value: "Pumps and Generator", label: "Pumps and Generator" },
  { value: "Special Contractors", label: "Special Contractors" },
];
