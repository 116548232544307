import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Input,
  theme,
  Typography,
  Alert,
  Select,
  Upload,
  Image,
} from "antd";
import { categories } from "../../data/categoryData";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;
const API_KEY = process.env.REACT_APP_API_KEY;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function Register() {
  const { token } = useToken();
  const screens = useBreakpoint();

  const primaryColor = "#ED6C02";
  const styles = {
    container: {
      margin: "auto auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "580px",
      borderRadius: "7px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
    forgotPassword: {
      float: "right",
    },
    header: {
      marginBottom: token.marginXL,
      textAlign: "center",
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
  };

  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);

  const navigate = useNavigate();

  // checking authorization
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(API_KEY + "/product/create", {
        withCredentials: true,
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const errorMessage = error.response.data.message;
          const redirectUrl = "/addProduct";
          navigate(`/login?error=${errorMessage}&redirectUrl=${redirectUrl}`);
        } else if (error.response.status === 403) {
          setError("Sorry, but you are not authorized to access this page!");
          navigate("/notAuthorized");
        }
        console.error(error);
      });
  }, []);

  const onFinish = (values) => {
    setIsProcessing(true);
    const formData = new FormData();
    formData.append("ProductId", values.ProductId);
    formData.append("Name", values.Name);
    formData.append("Description", values.Description);
    formData.append("Category", values.Category);
    formData.append("Price", values.Price);
    formData.append("Quantity", values.Quantity);
    formData.append("Unit", values.Unit);
    formData.append("Units_per_Pack", values.Units_per_Pack);
    formData.append("MinimumOrder", values.MinimumOrder);

    // Add the selected image if available
    if (selectedImages.length > 0) {
      for (const image of selectedImages) {
        formData.append("image", image);
      }
    }

    const token = localStorage.getItem("accessToken");
    axios
      .post(`${API_KEY}/product/create`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          setError(null);
          setSuccess(response.data.message + " Redirecting...");
          navigate("/dashboard");
          setSuccess(null);
        }
      })
      .catch((error) => {
        console.log(error);
        setSuccess(null);
        setError(error.response.data.message);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = (info) => {
    const newImages = info.fileList.map((file) => file.originFileObj);
    setSelectedImages(newImages);
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        {/* header */}
        <div style={styles.header}>
          <Title style={styles.title}>Add Product</Title>
          {error && !success && <Alert message={error} type="error" showIcon />}
          {success && !error && (
            <Alert message={success} type="success" showIcon />
          )}
          {!success && !error && (
            <Text style={styles.text}>
              Fill out the product information below.
            </Text>
          )}
        </div>

        {/* form */}
        <Form
          name="product_registration"
          initialValues={{ Units_per_Pack: 1, MinimumOrder: 1 }}
          onFinish={onFinish}
          layout="vertical"
          requiredMark="optional"
          autoComplete="off"
        >
          <Form.Item
            name="ProductId"
            label="Product ID"
            rules={[
              {
                required: true,
                message: "Product ID is required!",
              },
              {
                validator: (_, value) =>
                  /^\s*$/.test(value)
                    ? Promise.reject("Product ID can not be only whitespaces!")
                    : Promise.resolve(),
              },
            ]}
            hasFeedback
          >
            <Input showCount maxLength={10} placeholder="Enter product id" />
          </Form.Item>

          <Form.Item
            name="Name"
            label="Product Name"
            rules={[
              {
                required: true,
                message: "Product name is required!",
              },
              {
                validator: (_, value) =>
                  /^\s*$/.test(value)
                    ? Promise.reject(
                        "Product Name can not be only whitespaces!"
                      )
                    : Promise.resolve(),
              },
            ]}
            hasFeedback
          >
            <Input showCount maxLength={50} placeholder="Enter product name" />
          </Form.Item>

          <Form.Item
            name="Description"
            label="Description"
            rules={[
              {
                required: true,
                message: "Product description is required!",
              },
              {
                validator: (_, value) =>
                  /^\s*$/.test(value)
                    ? Promise.reject(
                        "Product Description can not be only whitespaces!"
                      )
                    : Promise.resolve(),
              },
            ]}
            hasFeedback
          >
            <Input.TextArea
              rows={4}
              showCount
              maxLength={300}
              placeholder="Enter a detailed description of your product"
              style={{ maxHeight: "210px" }}
            />
          </Form.Item>

          <Form.Item
            name="Category"
            label="Category"
            rules={[
              {
                required: true,
                message: "Product category is required!",
              },
            ]}
            hasFeedback
          >
            <Select placeholder="Select product category">
              {categories.map((category) => (
                <Select.Option key={category.value} value={category.value}>
                  {category.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="Price"
            label="Price"
            rules={[
              {
                required: true,
                message: "Product price is required!",
              },
              {
                validator: (_, value) =>
                  !isNaN(value) && parseFloat(value) >= 0
                    ? Promise.resolve()
                    : Promise.reject(
                        "Please enter a valid price (positive number only)!"
                      ),
              },
            ]}
            hasFeedback
          >
            <Input
              addonBefore="$"
              type="number"
              placeholder="Enter product price"
            />
          </Form.Item>

          <Form.Item
            name="Quantity"
            label="Quantity"
            rules={[
              {
                required: true,
                message: "Product quantity is a required field!",
              },
              {
                validator: (_, value) =>
                  !isNaN(value) && parseInt(value) > 0 // Check for number and positive integer
                    ? Promise.resolve()
                    : Promise.reject(
                        "Please enter a valid quantity (positive whole number only)!"
                      ),
              },
            ]}
            hasFeedback
          >
            <Input type="number" placeholder="Enter product quantity" />
          </Form.Item>

          <Form.Item
            name="Unit"
            label="Unit"
            rules={[
              {
                required: true,
                message: "Product unit is required!",
              },

              {
                validator: (_, value) =>
                  /^[^0-9]+$/.test(value)
                    ? Promise.resolve()
                    : Promise.reject(
                        "Please enter a valid unit (no numbers allowed)!"
                      ),
              },
            ]}
            hasFeedback
          >
            <Input
              showCount
              maxLength={10}
              placeholder="Enter product unit (e.g pieces, grams, liters...)"
            />
          </Form.Item>

          <Form.Item
            name="Units_per_Pack"
            label="Units Per Pack"
            rules={[
              {
                required: true,
                message: "Units per pack is required!",
              },
              {
                validator: (_, value) =>
                  !isNaN(value) && parseInt(value) > 0
                    ? Promise.resolve()
                    : Promise.reject(
                        "Please enter a valid number of units per pack (positive whole number only)!"
                      ),
              },
            ]}
            hasFeedback
          >
            <Input type="number" placeholder="Enter units per pack" />
          </Form.Item>

          <Form.Item
            name="MinimumOrder"
            label="Minimum Order"
            rules={[
              {
                validator: (_, value) =>
                  !isNaN(value) && parseInt(value) >= 1
                    ? Promise.resolve()
                    : Promise.reject(
                        "Please enter a valid minimum order quantity (positive whole number greater than or equal to 1)!"
                      ),
              },
            ]}
            hasFeedback
          >
            <Input type="number" placeholder="Enter minimum order quantity" />
          </Form.Item>

          <Form.Item
            name="image"
            label="Product Images"
            rules={[
              {
                required: true,
                message: "Please enter at least one image for your product!",
              },
            ]}
            hasFeedback
          >
            <Upload
              multiple
              listType="picture-card"
              onPreview={handlePreview} // Integrate preview functionality
              onChange={handleChange} // Existing function for file changes
              // fileList={selectedImages} // Use fileList prop for image data
            >
              {selectedImages.length > 5 && (
                <div>You can only upload up to 5 images</div>
              )}
              <Button>Select Images</Button>
            </Upload>
          </Form.Item>

          <Form.Item style={{ marginBottom: "15px" }}>
            <Button
              block="true"
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: primaryColor }}
              disabled={isProcessing}
            >
              {isProcessing ? "Adding Product..." : "Add Product"}
            </Button>
          </Form.Item>
        </Form>

        {previewImage && (
          <Image
            wrapperStyle={{
              display: "none",
            }}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) => setPreviewOpen(visible),
              afterOpenChange: (visible) => !visible && setPreviewImage(""),
            }}
            src={previewImage}
          />
        )}
      </div>
    </section>
  );
}
