import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Grid, Typography, theme, Modal, Alert } from "antd";
import { Context } from "../../context/Contexts";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Title, Text } = Typography;
const API_KEY = process.env.REACT_APP_API_KEY;

export default function ViewProfile() {
  axios.defaults.withCredentials = true;

  const { token } = useToken();
  const screens = useBreakpoint();

  const primaryColor = "#ED6C02";
  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "450px",
      borderRadius: "7px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
    header: {
      marginBottom: token.marginXL,
      textAlign: "center",
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
    fields: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "10px",
    },
    label: {
      fontSize: "13px",
      marginBottom: "0px",
    },
    info: {
      fontSize: "17px",
      fontWeight: 400,
    },
  };

  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState({
    FullName: "",
    Email: "",
    Username: "",
    Role: "",
  });
  const [success, setSuccess] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const successMessage = searchParams.get("success");
  const { authState, setAuthState } = useContext(Context);

  useEffect(() => {
    if (successMessage) {
      handleShowAlert();
      setSuccess(successMessage);
    }
    const fetchUserData = () => {
      const token = localStorage.getItem("accessToken");
      axios
        .get(`${API_KEY}/user/getUser`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setUserData(response.data);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            const errorMessage = error.response.data.message;
            const redirectUrl = "/profile";
            navigate(`/login?error=${errorMessage}&redirectUrl=${redirectUrl}`);
          }
          console.error(error);
        });
    };

    fetchUserData();
  }, []);

  const handleLogout = async () => {
    const token = localStorage.getItem("accessToken");
    axios
      .post(`${API_KEY}/auth/logout`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAuthState(false);
          navigate("/login");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteAccount = () => {
    setShowModal(true);
  };

  const handleModalConfirm = async () => {
    const token = localStorage.getItem("accessToken");
    await axios
      .delete(`${API_KEY}/user/deleteAccount`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const errorMessage = error.response.data.message;
          const redirectUrl = "/profile";
          navigate(`/login?error=${errorMessage}&redirectUrl=${redirectUrl}`);
        } else if (error.response.status === 403) {
          navigate("/notAuthorized");
        } else if (error.response.status === 404) {
          console.log(error.response);
          navigate("/profile");
        }

        console.error(error);
      });

    handleLogout();
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  const handleShowAlert = () => {
    setIsAlertVisible(true);
    setTimeout(() => {
      setIsAlertVisible(false);
    }, 3000);
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        {/* header */}
        <div style={styles.header}>
          <Title style={styles.title}>Profile</Title>
          {isAlertVisible && success && (
            <Alert message={success} type="success" showIcon />
          )}
        </div>

        <div style={styles.fields}>
          <Text type="secondary" style={styles.label}>
            Full Name
          </Text>
          <Text style={styles.info}>{userData.FullName}</Text>
        </div>

        <div style={styles.fields}>
          <Text type="secondary" style={styles.label}>
            Email
          </Text>
          <Text style={styles.info}>{userData.Email}</Text>
        </div>

        <div style={styles.fields}>
          <Text type="secondary" style={styles.label}>
            Username
          </Text>
          <Text style={styles.info}>{userData.Username}</Text>
        </div>

        <Button
          type="primary"
          block="true"
          style={{
            backgroundColor: primaryColor,
            marginTop: "10px",
            marginRight: "10px",
          }}
          onClick={() => navigate("/editProfile")}
        >
          Edit Profile
        </Button>

        <Button
          type="primary"
          block="true"
          style={{
            backgroundColor: "#212121",
            marginTop: "10px",
            marginRight: "10px",
          }}
          onClick={() => navigate("/changePassword")}
        >
          Change Password
        </Button>

        {userData.Role === "Client" && (
          <Button
            type="primary"
            block="true"
            danger
            style={{ marginTop: "10px" }}
            onClick={handleDeleteAccount}
          >
            Delete Account
          </Button>
        )}

        <Modal
          open={showModal}
          title="Confirm Account Deletion"
          onOk={handleModalConfirm}
          onCancel={handleModalCancel}
          okText="Delete"
          okButtonProps={{ danger: true }}
          cancelText="Cancel"
          centered
        >
          <p>
            Are you sure you want to delete your account? This action cannot be
            undone.
          </p>
        </Modal>
      </div>
    </section>
  );
}
