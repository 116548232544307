import React, { useState } from "react";
import TweenOne from "rc-tween-one";
import BannerAnim, { Element, Arrow } from "rc-banner-anim";
import "./styles/banner.css";
import bannerData from "../data/bannerData";
import pic1 from "../asset/pic1.jpg";
import pic2 from "../asset/pic2.jpg";
import pic3 from "../asset/pic3.jpg";

const BgElement = Element.BgElement;

const CustomBanner = () => {
  const imgArray = [pic1, pic2, pic3];
  const [intShow, setIntShow] = useState(0);
  const [thumbEnter, setThumbEnter] = useState(false);

  const onChange = (type, int) => {
    if (type === "before") {
      setIntShow(int);
    }
  };

  const handleMouseEnter = () => setThumbEnter(true);
  const handleMouseLeave = () => setThumbEnter(false);

  return (
    <BannerAnim
      onChange={onChange}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      prefixCls="custom-arrow-thumb"
      autoPlay
      autoPlaySpeed={5000}
      type={"grid"}
    >
      {/* Banner Data */}
      {bannerData.map((item, index) => (
        <Element key={index} prefixCls="banner-user-elem">
          <BgElement
            key="bg"
            className="bg"
            style={{
              backgroundImage: `url(${imgArray[index]})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <TweenOne
            className="banner-user-title"
            animation={{ y: 30, opacity: 1, type: "from" }}
            style={{
              fontWeight: "600",
              fontSize: "35px",
              textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
            }}
          >
            {item.title}
          </TweenOne>
          <TweenOne
            className="banner-user-text"
            animation={{ y: 30, opacity: 1, type: "from", delay: 100 }}
            style={{
              fontWeight: "500",
              marginTop: "10px",
              fontSize: "17px",
              textShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
            }}
          >
            {item.text}
          </TweenOne>
        </Element>
      ))}

      {/* Left Arrow */}
      <Arrow
        key="prev"
        arrowType="prev"
        prefixCls="user-arrow"
        component={TweenOne}
      >
        <i
          class="fa-solid fa-caret-left"
          style={{
            color: "white",
            fontSize: "30px",
            cursor: "pointer",
            marginLeft: "20px",
          }}
        ></i>
      </Arrow>

      {/* Right Arrow */}
      <Arrow
        key="next"
        arrowType="next"
        prefixCls="user-arrow"
        component={TweenOne}
      >
        <i
          class="fa-solid fa-caret-right"
          style={{
            color: "white",
            fontSize: "30px",
            cursor: "pointer",
            marginRight: "20px",
          }}
        ></i>
      </Arrow>
    </BannerAnim>
  );
};

export default CustomBanner;
