import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Flex, theme, Grid, Alert, Spin, Empty, Pagination } from "antd";
import { Chip, Typography } from "@mui/material";
import { Context } from "../../context/Contexts";
import { categories } from "../../data/categoryData";
import ProductCard from "../../components/ProductCard";

const { useToken } = theme;
const { useBreakpoint } = Grid;

const API_KEY = process.env.REACT_APP_API_KEY;

const CategoryPage = () => {
  const screens = useBreakpoint();

  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const { addToCart, cartItems } = useContext(Context);

  const handleFilterButtonClick = (selectedCategory) => {
    if (selectedCategories.includes(selectedCategory)) {
      let filters = selectedCategories.filter((el) => el !== selectedCategory);
      setSelectedCategories(filters);
    } else {
      setSelectedCategories([...selectedCategories, selectedCategory]);
    }
  };

  useEffect(() => {
    filterItems();
  }, []);

  const filterItems = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_KEY}/product/getProducts`);
      setProducts(response.data);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setError(
          "Seems like there is a network problem. Please try again later!"
        );
      } else {
        setError("An error occurred while fetching products!");
        console.error(err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCategories.length > 0) {
      let tempItems = selectedCategories.map((selectedCategory) => {
        let temp = products.filter(
          (product) => product.Category === selectedCategory
        );
        return temp;
      });
      setFilteredProducts(tempItems.flat());
    } else {
      setFilteredProducts([...products]);
    }
  }, [selectedCategories, products]);

  return (
    <Box
      sx={{
        backgroundColor: "#FFF",
        minHeight: "Calc(100vh - 114px)",
        marginTop: "64px",
      }}
    >
      {/* title */}
      <Typography
        component="p"
        variant="h5"
        textAlign="center"
        sx={{ marginTop: 7, fontWeight: 700 }}
      >
        Product Category
      </Typography>

      {/* category chips */}
      <Container
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          paddingY: 4,
        }}
      >
        {categories.map((category, index) => (
          <Chip
            key={index}
            label={category.label}
            color="warning"
            variant={
              selectedCategories?.includes(category.value) ? "" : "outlined"
            }
            onClick={() => handleFilterButtonClick(category.value)}
          />
        ))}
      </Container>

      {isLoading && !error && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <Spin tip="Loading" />
        </div>
      )}

      {error && !isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Alert message={error} type="error" />
        </div>
      )}

      {!isLoading && !error && filteredProducts.length > 0 && (
        <Container
          sx={{
            flexGrow: 1,
            backgroundColor: "#FFF",
            borderRadius: 3,
            minHeight: "55vh",
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Flex
              wrap
              gap="small"
              justify="center"
              style={{
                padding: "0px 30px",
                maxWidth: "1200px",
              }}
            >
              {filteredProducts.map((product, index) => (
                <ProductCard
                  key={index}
                  product={product}
                  addToCart={addToCart}
                  cartItems={cartItems}
                  screens={screens}
                />
              ))}
            </Flex>
          </div>
        </Container>
      )}

      {!isLoading && !error && filteredProducts.length <= 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "30rem",
          }}
        >
          <Empty
            description="No products found with this category"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        </div>
      )}
    </Box>
  );
};

export default CategoryPage;
