import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Flex, Typography, Grid } from "antd";
import { Context } from "../context/Contexts";

const { useBreakpoint } = Grid;

const primaryColor = "#ED6C02";

const App = () => {
  const screens = useBreakpoint();

  const navigate = useNavigate();
  const { addToCart } = useContext(Context);

  const handleClick = () => {
    addToCart(7);
    navigate("/cart");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "30px 0",
      }}
    >
      <Flex
        wrap
        gap="small"
        justify="center"
        style={{
          padding: "0px 30px",
          maxWidth: "1200px",
        }}
      >
        <Card
          hoverable
          style={{
            width: screens.lg
              ? "1035px"
              : screens.sm
              ? "700px"
              : "calc(100vw - 60px)",
          }}
          styles={{
            body: {
              padding: 0,
              overflow: "hidden",
            },
          }}
        >
          <Flex
            justify="space-between"
            style={{ flexDirection: screens.sm ? "row" : "column" }}
          >
            <img
              alt="avatar"
              src="./assets/discount.webp"
              style={{
                display: "block",
                width: screens.sm ? "250px" : "100%",
              }}
            />
            <Flex
              vertical
              align="flex-end"
              justify="space-between"
              style={{
                padding: 32,
              }}
            >
              <Typography.Title level={4}>
                Don't miss out! Enjoy a fantastic 20% discount on this product.
                Explore our collection and discover the hidden gem waiting for
                you.
              </Typography.Title>
              <Button
                type="primary"
                onClick={handleClick}
                style={{ backgroundColor: primaryColor }}
              >
                Order Now
              </Button>
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </div>
  );
};
export default App;
