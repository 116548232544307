import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Alert, Breadcrumb, Spin, Table } from "antd";

const API_KEY = process.env.REACT_APP_API_KEY;

const columns = [
  {
    title: "Order ID",
    dataIndex: "orderId",
    defaultSortOrder: "decend",
    sorter: (a, b) => a.orderId - b.orderId,
  },
  {
    title: "Product Name",
    dataIndex: "productName",
    key: "productName",
    render: (text, record) => (
      <Link to={`/product/detail/${record.productId}`}>{text}</Link>
    ),
  },
  {
    title: "Full Name",
    dataIndex: "fullname",
    key: "fullname",
    render: (text, record) => (
      <Link to={`/profile/${record.userId}`}>{text}</Link>
    ),
  },
  {
    title: "User Name",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Unit Price",
    dataIndex: "unitPrice",
    defaultSortOrder: "decend",
    sorter: (a, b) => a.unitPrice - b.unitPrice,
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    defaultSortOrder: "decend",
    sorter: (a, b) => a.quantity - b.quantity,
  },
  {
    title: "Total Price",
    dataIndex: "totalPrice",
    defaultSortOrder: "decend",
    sorter: (a, b) => a.totalPrice - b.totalPrice,
  },
];

const OrderList = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await axios.get(`${API_KEY}/order/getOrders`, {
          withCredentials: true,
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        const transformedData = response.data.map((order, index) => ({
          key: index,
          orderId: order.OrderId,
          productId: order.ProductId,
          productName: order.ProductName,
          userId: order.UserId,
          fullname: order.UserFullName,
          username: order.UserName,
          unitPrice: order.ProductPrice,
          quantity: order.OrderQuantity,
          totalPrice: order.OrderPrice,
        }));

        setData(transformedData);
      } catch (error) {
        if (error.response.status === 404) {
          setError(
            "Sorry, there seems to be a problem while fetching the order report. Please try again later."
          );
        } else if (error.response.status === 401) {
          const errorMessage = error.response.data.message;
          const redirectUrl = "/dashboard";
          navigate(`/login?error=${errorMessage}&redirectUrl=${redirectUrl}`);
        } else if (error.response.status === 403) {
          navigate("/notAuthorized");
        } else {
          setError("An error occurred while fetching order data!");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, [navigate]);

  return (
    <>
      <h1>Order List</h1>

      <Breadcrumb
        style={{
          margin: "0 0 30px 5px",
        }}
      >
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Order List</Breadcrumb.Item>
      </Breadcrumb>

      <div>
        {isLoading && !error && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            <Spin tip="Loading" />
          </div>
        )}

        {error && !isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Alert message={error} type="error" />
          </div>
        )}

        {!isLoading && !error && (
          <Table
            style={{ padding: "0px" }}
            columns={columns}
            dataSource={data}
            showSorterTooltip={{
              target: "sorter-icon",
            }}
          />
        )}
      </div>
    </>
  );
};

export default OrderList;
