import React, { useContext, useEffect, useState } from "react";
import {
  OrderedListOutlined,
  ProductOutlined,
  UserOutlined,
  AreaChartOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import OverallReport from "../../components/Report";
import ProductList from "../../components/ProductList";
import UserList from "../../components/UserList";
import OrderList from "../../components/OrderList";
import { Context } from "../../context/Contexts";
const { Content, Sider } = Layout;

const items = [
  {
    key: "0",
    icon: <AreaChartOutlined />,
    label: "Overall Report",
  },
  {
    key: "1",
    icon: <ProductOutlined />,
    label: "Product List",
  },
  {
    key: "2",
    icon: <UserOutlined />,
    label: "Client List",
  },
  {
    key: "3",
    icon: <OrderedListOutlined />,
    label: "Order List",
  },
];

const Dashboard = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const { selectedMenuItem, setSelectedMenuItem } = useContext(Context);

  return (
    <Layout style={{ height: "auto", minHeight: "calc(100vh - 64px)" }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        style={{ backgroundColor: "black" }}
      >
        <div className="demo-logo-vertical" style={{ marginTop: "20px" }} />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={selectedMenuItem}
          items={items}
          onClick={(obj) => setSelectedMenuItem(obj.key)}
          style={{ backgroundColor: "black" }}
        />
      </Sider>

      <Layout>
        <Content
          style={{
            margin: "24px 16px",
          }}
        >
          <div
            style={{
              padding: 24,
              minHeight: 360,
              height: "100%",
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {selectedMenuItem === "0" && <OverallReport />}
            {selectedMenuItem === "1" && <ProductList />}
            {selectedMenuItem === "2" && <UserList />}
            {selectedMenuItem === "3" && <OrderList />}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default Dashboard;
