import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "64px",
        width: "100vw",
        maxWidth: "1700px",
        marginX: "auto",
        minHeight: "Calc(100vh - 64px)",
        [theme.breakpoints.down("md")]: {
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        },
        [theme.breakpoints.down("sm")]: {
          gap: "20px",
        },
      })}
    >
      {/* text */}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        gap={2}
        width="50%"
        paddingLeft={7}
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            alignItems: "center",
            width: "90%",
            paddingX: "20px",
          },
        })}
      >
        <Typography
          component="p"
          variant="h3"
          fontWeight={900}
          sx={(theme) => ({
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              justifyContent: "center",
              fontSize: "30px",
            },
          })}
        >
          Explore Our Top-Rated Construction Supplies
        </Typography>

        <Typography variant="p">
          We offer a vast selection of construction tools for every project.
          Find what you need, fast!
        </Typography>

        <Typography variant="p" marginBottom={2}>
          ሁሌም አማራጭ ተመልከቱ!
        </Typography>

        <Button
          variant="contained"
          color="warning"
          sx={(theme) => ({
            width: "200px",
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          })}
          onClick={() => navigate("/product")}
        >
          Shop Now
        </Button>
      </Box>

      {/* Image */}
      <Box
        display="flex"
        justifyContent="center"
        width="50%"
        paddingRight={4}
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            width: "65%",
            justifyContent: "center",
            paddingX: "20px",
          },
          [theme.breakpoints.down("sm")]: {
            width: "80%",
          },
        })}
      >
        <img
          alt={"A picture of a helmet"}
          src={"./assets/Helmet.png"}
          height="auto"
          width="85%"
        />
      </Box>

      <Button
        variant="contained"
        color="warning"
        sx={(theme) => ({
          display: "none",
          width: "200px",
          [theme.breakpoints.down("sm")]: {
            display: "flex",
          },
        })}
        onClick={() => navigate("/product")}
      >
        Shop Now
      </Button>
    </Box>
  );
};

export default Home;
