import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Flex,
  Form,
  Grid,
  Input,
  theme,
  Typography,
} from "antd";
import { NumberOutlined } from "@ant-design/icons";
import { Context } from "../../context/Contexts";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;
const API_KEY = process.env.REACT_APP_API_KEY;

export default function Login() {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [timerCount, setTimerCount] = useState(60);
  const [disable, setDisable] = useState(true);
  const { token } = useToken();
  const screens = useBreakpoint();

  const navigate = useNavigate();
  const { OTP, setOTP } = useContext(Context);

  const resendOTP = async () => {
    let otp = Math.floor(Math.random() * 9000 + 1000);

    if (disable) return;

    setDisable(true);
    alert("A new OTP verification code has been sent to your email address!");
    setTimerCount(60);

    // axios
    //   .post(API_KEY + "/sendEmail", {
    //     OTP: otp,
    //     username: usernameForResettingPwd,
    //   })
    //   .then((response) => {
    //     if (response.status === 200) {
    //       setDisable(true);
    //       alert(
    //         "A new OTP verification code has been sent to your email address!"
    //       );
    //       setTimerCount(60);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error?.message);
    //   });
  };

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px",
      borderRadius: "7px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
      color: "#ED6C02",
    },
    header: {
      marginBottom: token.marginXL,
      textAlign: "center",
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
    beteseriBgColor: {
      backgroundColor: "#ED6C02",
    },
    beteseriTextColor: {
      color: "#ED6C02",
    },
  };

  const onFinish = async (values) => {
    // if (values.verificationCode == OTP) {
    //   setError(null);
    //   setSuccess("You are verified!");
    //   navigate("/resetPassword");
    //   setSuccess(null);
    //   setOTP(0);
    // } else {
    //   setSuccess(null);
    //   setError(
    //     "The code you entered is not correct! Please try again or resend the code."
    //   );
    // }
    console.log(values);
    navigate("/resetPassword");
  };

  useEffect(() => {
    let interval = setInterval(() => {
      setTimerCount((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        if (lastTimerCount <= 1) setDisable(false);
        if (lastTimerCount <= 0) return lastTimerCount;
        return lastTimerCount - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [disable]);

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        {/* header */}
        <div style={styles.header}>
          <Title style={styles.title}>Email Verification</Title>

          {error && !success && <Alert message={error} type="error" showIcon />}
          {success && !error && (
            <Alert message={success} type="success" showIcon />
          )}
          {!success && !error && (
            <Text style={styles.text}>
              We have sent a code to your email address!
            </Text>
          )}
        </div>

        {/* form */}

        <Form
          name="otp_verification"
          onFinish={onFinish}
          layout="vertical"
          requiredMark="optional"
        >
          <Form.Item
            name="verificationCode"
            rules={[
              {
                required: true,
                message: "Verification code is required!",
              },
              {
                validator: (_, value) =>
                  value && /^\d{4}$/.test(value)
                    ? Promise.resolve()
                    : Promise.reject(
                        "Please enter a valid 4-digit OTP code (numbers only, no spaces)"
                      ),
              },
            ]}
            hasFeedback
          >
            <Input
              prefix={<NumberOutlined />}
              type="text"
              placeholder="Verification Code"
              autoComplete="off"
              showCount
              maxLength={4}
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: "0px" }}>
            <Button
              block="true"
              type="primary"
              htmlType="submit"
              style={styles.beteseriBgColor}
            >
              Verify Account
            </Button>

            <div style={styles.footer}>
              <Text style={styles.text}>Didn't recieve code?</Text>{" "}
              <Link
                onClick={() => resendOTP()}
                style={disable ? styles.text : styles.beteseriTextColor}
                disabled={disable}
              >
                {disable ? `Resend OTP after ${timerCount}s` : "Resend OTP"}
              </Link>
            </div>
          </Form.Item>
        </Form>
      </div>
    </section>
  );
}
