import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button
          type="primary"
          onClick={() => navigate("/")}
          style={{ backgroundColor: "#ED6C02" }}
        >
          Back Home
        </Button>
      }
    />
  );
};

export default Unauthorized;
