import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Breadcrumb,
  Card,
  Col,
  Flex,
  Row,
  Spin,
  Typography,
} from "antd";
import {
  ProductOutlined,
  UserOutlined,
  AreaChartOutlined,
} from "@ant-design/icons";
import List from "./List";

const API_KEY = process.env.REACT_APP_API_KEY;

const { Text } = Typography;

const Report = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [topBuyersList, setTopBuyersList] = useState([]);
  const [topProductList, setTopProductList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await axios.get(`${API_KEY}/order/statistics`, {
          withCredentials: true,
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        setData(response.data.stats);

        const topSellingProducts = response.data.stats.topSellingProducts;
        const TopProductList = topSellingProducts.map((product) => {
          const { Name, TotalOrders } = product;
          return `${Name} (${TotalOrders} orders made)`;
        });
        setTopProductList(TopProductList);

        const topBuyers = response.data.stats.topBuyers;
        const TopBuyersList = topBuyers.map((user) => user.FullName);
        setTopBuyersList(TopBuyersList);
      } catch (error) {
        if (error.response.status === 401) {
          const errorMessage = error.response.data.message;
          const redirectUrl = "/dashboard";
          navigate(`/login?error=${errorMessage}&redirectUrl=${redirectUrl}`);
        } else if (error.response.status === 403) {
          navigate("/notAuthorized");
        } else {
          setError(
            "Sorry, there seems to be a problem while fetching the overall report. Please try again later."
          );
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, [navigate]);

  return (
    <>
      <h1>Overall Report</h1>

      <Breadcrumb
        style={{
          margin: "0 0 30px 5px",
        }}
      >
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Overall Report</Breadcrumb.Item>
      </Breadcrumb>

      {isLoading && !error && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <Spin tip="Loading" />
        </div>
      )}

      {error && !isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Alert message={error} type="error" />
        </div>
      )}

      {!isLoading && !error && (
        <>
          <Row gutter={16} style={{ margin: "10px 0px" }}>
            <Col span={8}>
              <Card hoverable bordered={true}>
                <Flex gap="middle" horizontal align="center">
                  <div
                    style={{
                      alignSelf: "start",
                      width: "7px",
                      height: "90px",
                      backgroundColor: "purple",
                      borderRadius: "2px",
                    }}
                  ></div>
                  <div style={{ flexGrow: 1 }}>
                    <Text type="secondary">Orders</Text>
                    <Typography.Title
                      level={1}
                      style={{
                        margin: 0,
                      }}
                    >
                      {data.totalOrders}
                    </Typography.Title>
                    <Text type="secondary">All orders made so far</Text>
                  </div>
                  <AreaChartOutlined
                    style={{
                      fontSize: 40,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "7px",
                      width: "75px",
                      height: "75px",
                      color: "purple",
                      backgroundImage:
                        "linear-gradient(to top, #C28CD1 0%, #FDE7F7 100%)",
                    }}
                  />
                </Flex>
              </Card>
            </Col>

            <Col span={8}>
              <Card hoverable bordered={true}>
                <Flex gap="middle" horizontal align="center">
                  <div
                    style={{
                      alignSelf: "start",
                      width: "7px",
                      height: "90px",
                      backgroundColor: "#0BB156",
                      borderRadius: "2px",
                    }}
                  ></div>
                  <div style={{ flexGrow: 1 }}>
                    <Text type="secondary">Total Products</Text>
                    <Typography.Title
                      level={1}
                      style={{
                        margin: 0,
                      }}
                    >
                      {data.totalProducts}
                    </Typography.Title>
                    <Text type="secondary">
                      <Text type="success">{data.activeProducts}</Text> active
                      and
                      <Text type="danger"> {data.deletedProducts}</Text> deleted
                    </Text>
                  </div>
                  <ProductOutlined
                    style={{
                      fontSize: 40,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "7px",
                      width: "75px",
                      height: "75px",
                      color: "#0BB156",
                      backgroundImage:
                        "linear-gradient(to top, #79F5B1 0%, #E7FDF1 100%)",
                    }}
                  />
                </Flex>
              </Card>
            </Col>

            <Col span={8}>
              <Card hoverable bordered={true}>
                <Flex gap="middle" horizontal align="center">
                  <div
                    style={{
                      alignSelf: "start",
                      width: "7px",
                      height: "90px",
                      backgroundColor: "#FF9900",
                      borderRadius: "2px",
                    }}
                  ></div>
                  <div style={{ flexGrow: 1 }}>
                    <Text type="secondary">Total Users</Text>
                    <Typography.Title
                      level={1}
                      style={{
                        margin: 0,
                      }}
                    >
                      {data.totalUsers}
                    </Typography.Title>
                    <Text type="secondary">
                      <Text type="warning">{data.adminUsers}</Text> admins and
                      <Text type="warning"> {data.clientUsers}</Text> clients
                    </Text>
                  </div>
                  <UserOutlined
                    style={{
                      fontSize: 40,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "7px",
                      width: "75px",
                      height: "75px",
                      color: "#FF9900",
                      backgroundImage:
                        "linear-gradient(to top, #FFCB45 0%, #FFEBB8 100%)",
                    }}
                  />
                </Flex>
              </Card>
            </Col>
          </Row>

          <Row style={{ margin: "10px 0px" }}>
            <Col span={12}>
              <List
                listTitle={"Top 5 Most Sold Products"}
                data={topProductList}
              />
            </Col>

            <Col span={12}>
              <List listTitle={"Top 5 Buyers"} data={topBuyersList} />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Report;
