import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Form, Grid, Input, theme, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { Context } from "../../context/Contexts";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;
const API_KEY = process.env.REACT_APP_API_KEY;

export default function Login() {
  //   axios.defaults.withCredentials = true;

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { token } = useToken();
  const screens = useBreakpoint();

  const navigate = useNavigate();
  const { resetUserEmail, setResetUserEmail } = useContext(Context);

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px",
      borderRadius: "7px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
    header: {
      marginBottom: token.marginXL,
      textAlign: "center",
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
    beteseriBgColor: {
      backgroundColor: "#ED6C02",
      marginBottom: "18px",
    },
  };

  const onFinish = (values) => {
    axios
      .put(`${API_KEY}/auth/resetPassword`, {
        newPassword: values.newPassword,
        userEmail: resetUserEmail,
      })
      .then((response) => {
        if (response.status === 200) {
          setError(null);
          setSuccess(response.data.message);

          setResetUserEmail(null);

          navigate("/login");

          setSuccess(null);
        }
      })
      .catch((error) => {
        console.error(error);
        setError(error?.response?.data?.message);
      });
    navigate("/login");
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        {/* header */}
        <div style={styles.header}>
          <Title style={styles.title}>Change Password</Title>
          {error && !success && <Alert message={error} type="error" showIcon />}
          {success && !error && (
            <Alert message={success} type="success" showIcon />
          )}
          {!success && !error && (
            <Text style={styles.text}>Please enter your new password.</Text>
          )}
        </div>

        {/* form */}
        <Form
          name="reset_password"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
          layout="vertical"
          requiredMark="optional"
        >
          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: "New Password is required!",
              },
              {
                validator: (_, value) =>
                  value &&
                  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]/.test(value) &&
                  value.length >= 8 &&
                  value.length <= 12
                    ? Promise.resolve()
                    : Promise.reject(
                        "Password must be 8 - 12 characters long and contain a lowercase letter, an uppercase letter, and a number!"
                      ),
              },
            ]}
            hasFeedback
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="New Password"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={["newPassword"]}
            rules={[
              {
                required: true,
                message: "Confirm Password is required!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Passwords do not match!");
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Confirm Password"
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: "0px" }}>
            <Button
              block="true"
              type="primary"
              htmlType="submit"
              style={styles.beteseriBgColor}
            >
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </section>
  );
}
