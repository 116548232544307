import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/Contexts";
import { Modal as AntModal } from "antd";
import { Box, Button, Divider, Grid, Modal, Typography } from "@mui/material";
import CartItem from "../../components/CartItem";

const API_KEY = process.env.REACT_APP_API_KEY;
const primaryColor = "#ED6C02";

const CartPage = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [showConformationModal, setShowConformationModal] = useState(false);

  const navigate = useNavigate();

  const {
    cartItems,
    addToCart,
    subtractFromCart,
    updateCartItemAmount,
    clearCart,
  } = useContext(Context);

  useEffect(() => {
    const fetchProducts = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await axios.get(`${API_KEY}/product/getProducts`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        setProducts(response.data);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setError(
            "Seems like there is a network problem. We are having a hard time getting the products in your cart!"
          );
        } else {
          setError("An error occurred while fetching products!");
          console.error(err);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const getTotalCartPriceAndQuantity = () => {
    let totalAmount = 0;
    let totalQuantity = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        const itemInfo = products.find((product) => product.id == Number(item));
        if (itemInfo) {
          totalAmount += cartItems[item] * itemInfo.Price;
          totalQuantity += cartItems[item];
        }
      }
    }
    return { totalAmount, totalQuantity };
  };

  const makeAnOrder = async () => {
    const orderItems = [];

    for (const productId in cartItems) {
      if (cartItems[productId] > 0) {
        const productInfo = products.find(
          (product) => product.id === Number(productId)
        );
        if (productInfo) {
          orderItems.push({
            ProductId: productInfo.id,
            Quantity: cartItems[productId],
            Price: productInfo.Price * cartItems[productId],
          });
        }
      }
    }

    if (orderItems.length === 0) {
      console.log("No items in cart to create order.");
      return;
    }

    const orderData = {
      items: orderItems,
    };

    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios.post(`${API_KEY}/order/create`, orderData, {
        withCredentials: true,
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      clearCart();
      setOpen(true);
      setShowConformationModal(false);
    } catch (error) {
      if (error.response.status === 401) {
        const errorMessage = error.response.data.message;
        const redirectUrl = "/cart";
        navigate(`/login?error=${errorMessage}&redirectUrl=${redirectUrl}`);
      }
      console.error("Error creating order:", error);
    }
  };

  const handleConfirmOrder = () => {
    makeAnOrder();
  };

  const { totalAmount, totalQuantity } = getTotalCartPriceAndQuantity();

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        marginTop: "64px",
        marginX: "auto",
        width: "100vw",
        maxWidth: "1700px",
        minHeight: "Calc(100vh - 64px)",
        [theme.breakpoints.down("md")]: {
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        },
        [theme.breakpoints.down("sm")]: {
          gap: "20px",
        },
      })}
    >
      {/* Cart list */}
      <Box
        sx={(theme) => ({
          width: "60%",
          margin: "3rem 2rem",
          border: "1px solid #dfdfdf",
          borderRadius: "7px",
          padding: "20px",
          boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;",
          [theme.breakpoints.down("md")]: {
            width: "80%",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="p" component="h2">
            My Cart
          </Typography>
          <Button color="warning" onClick={clearCart}>
            Clear
          </Button>
        </Box>

        <Box
          sx={{
            marginTop: "20px",
            minHeight: "Calc(100vh - 270px)",
          }}
        >
          {totalQuantity > 0 ? (
            <Grid
              spacing={{ xs: 2, md: 2 }}
              columns={2}
              height="Calc(100vh - 270px)"
              sx={{ overflowY: "auto" }}
            >
              {isLoading ? (
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "Calc(100vh - 350px)",
                  }}
                >
                  Loading...
                </Typography>
              ) : error ? (
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "Calc(100vh - 350px)",
                    color: "red",
                  }}
                >
                  {error}
                </Typography>
              ) : (
                products.map(
                  (product, index) =>
                    cartItems[product.id] > 0 && (
                      <Grid xs={4} sm={4} md={4} key={index}>
                        <CartItem product={product} index={index} />
                      </Grid>
                    )
                )
              )}
            </Grid>
          ) : (
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "Calc(100vh - 350px)",
              }}
            >
              No items in cart!
            </Typography>
          )}
        </Box>
      </Box>

      {/* Price display */}
      <Box
        sx={(theme) => ({
          width: "30%",
          margin: "3rem 2rem",
          backgroundColor: "#fff",
          border: "1px solid #dfdfdf",
          borderRadius: "7px",
          padding: "20px",
          boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;",
          [theme.breakpoints.down("md")]: {
            width: "80%",
            marginTop: "0",
          },
        })}
      >
        <Typography variant="p" component="h3" fontWeight="600">
          Cart Summary
        </Typography>
        <Divider sx={{ marginY: "20px" }} />
        <Box>
          <Typography>Total quantity: {totalQuantity} items</Typography>
          <Typography>Sub total: {totalAmount} ETB</Typography>
        </Box>
        <Divider sx={{ marginTop: "20px", marginBottom: "30px" }} />

        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "space-between",
            gap: "15px",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
            },
          })}
        >
          <Button
            variant="contained"
            color="warning"
            sx={{ flexGrow: "1" }}
            onClick={() => setShowConformationModal(true)}
            disabled={totalQuantity < 1}
          >
            Order
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "black", flexGrow: "1" }}
            onClick={() => navigate("/product")}
          >
            Continue Shopping
          </Button>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={(theme) => ({
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            px: 4,
            py: 6,
            [theme.breakpoints.down("md")]: {
              width: 500,
            },
            [theme.breakpoints.down("sm")]: {
              width: 300,
            },
          })}
        >
          <Typography id="modal-modal-title" variant="p" component="h2">
            Products Ordered Successfully
          </Typography>

          <Divider sx={{ my: 3 }} />

          <Typography sx={{ mt: 2 }}>
            Thank you for choosing Beteseri! We appreciate your business and
            look forward to serving you again in the future!
          </Typography>

          <Typography sx={{ mt: 2 }}>
            You can find us at the following location:
          </Typography>
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                alignItems: "start",
              },
            })}
          >
            <Typography sx={{ mt: 2 }}>
              Store Name: BETESERI <br />
              Phone: +251913057618. <br /> City: Addis Ababa <br />
              Country: Ethiopia <br />
            </Typography>

            <img
              src="./assets/Beteseri QR.png"
              alt="QR Code for Beteseri contact"
              style={{ width: "120px", height: "120px" }}
            />
          </Box>
        </Box>
      </Modal>

      {/* Confirmation Modal */}
      <AntModal
        open={showConformationModal}
        title="Order Conformation"
        onOk={handleConfirmOrder}
        onCancel={() => setShowConformationModal(false)}
        okText="Order Products"
        cancelText="Cancel"
        centered // Center the modal on the screen
      >
        <p>
          Are you sure you want to order the {totalQuantity} item(s) inside the
          cart?
        </p>
      </AntModal>
    </Box>
  );
};

export default CartPage;
