import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Alert, Breadcrumb, Spin, Table, Tag } from "antd";

const API_KEY = process.env.REACT_APP_API_KEY;

const columns = [
  {
    title: "User ID",
    dataIndex: "userId",
    defaultSortOrder: "ascend",
    sorter: (a, b) => a.userId - b.userId,
  },
  {
    title: "Full Name",
    dataIndex: "fullname",
    key: "fullname",
    render: (text, record) => (
      <Link to={`/profile/${record.userId}`}>{text}</Link>
    ),
  },
  {
    title: "User Name",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Banned Status",
    dataIndex: "isBanned",
    key: "isBanned",
    render: (_, { isBanned }) => (
      <>
        {isBanned === true ? (
          <Tag color="red">Banned</Tag>
        ) : (
          <Tag color="green">Not Banned</Tag>
        )}
      </>
    ),
  },
  {
    title: "Deleted Status",
    dataIndex: "isDeleted",
    key: "isDeleted",
    render: (_, { isDeleted }) => (
      <>
        {isDeleted === true ? (
          <Tag color="red">Deleted</Tag>
        ) : (
          <Tag color="green">Active</Tag>
        )}
      </>
    ),
  },
];

const UserList = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await axios.get(`${API_KEY}/user/getUsers`, {
          withCredentials: true,

          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        const transformedData = response.data.map((user, index) => ({
          key: index,
          userId: user.id,
          fullname: user.FullName,
          username: user.Username,
          email: user.Email,
          isBanned: user.isBanned,
          isDeleted: user.isDeleted,
        }));

        setData(transformedData);
      } catch (error) {
        if (error.response.status === 404) {
          setError(
            "Sorry, there seems to be a problem while fetching the clients data. Please try again later."
          );
        } else if (error.response.status === 401) {
          const errorMessage = error.response.data.message;
          const redirectUrl = "/user";
          navigate(`/login?error=${errorMessage}&redirectUrl=${redirectUrl}`);
        } else if (error.response.status === 403) {
          navigate("/notAuthorized");
        } else {
          setError("An error occurred while fetching users!");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [navigate]);

  return (
    <>
      <h1>Client List</h1>

      <Breadcrumb
        style={{
          margin: "0 0 30px 5px",
        }}
      >
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Client List</Breadcrumb.Item>
      </Breadcrumb>

      {isLoading && !error && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <Spin tip="Loading" />
        </div>
      )}

      {error && !isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Alert message={error} type="error" />
        </div>
      )}

      {!isLoading && !error && (
        <Table
          columns={columns}
          dataSource={data}
          showSorterTooltip={{
            target: "sorter-icon",
          }}
        />
      )}
    </>
  );
};

export default UserList;
