import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ContextProvider } from "./context/Contexts";
import HomePage from "./pages/Main/HomePage";
import DashboardPage from "./pages/Main/DashboardPage";
import NotFoundPage from "./pages/Main/NotFoundPage";
import NotAuthorizedPage from "./pages/Main/NotAuthorized";
import ProductPage from "./pages/Product/ProductPage";
import AddProductPage from "./pages/Product/AddProductPage";
import EditProductPage from "./pages/Product/EditProductPage";
import ProductDetailPage from "./pages/Product/ProductDetailPage";
import CategoryPage from "./pages/Product/CategoryPage";
import CartPage from "./pages/Product/CartPage";
import RegisterPage from "./pages/User/RegisterPage";
import LoginPage from "./pages/User/LoginPage";
import UserProfilePage from "./pages/User/UserProfilePage";
import ViewProfilePage from "./pages/User/ViewProfilePage";
import EditProfilePage from "./pages/User/EditProfilePage";
import ChangePasswordPage from "./pages/User/ChangePasswordPage";
import SendEmailPage from "./pages/Auth/SendEmailPage";
import EnterOtpPage from "./pages/Auth/EnterOtpPage";
import ResetPasswordPage from "./pages/Auth/ResetPasswordPage";
import Header from "./components/Header";

function App() {
  return (
    <div className="App">
      <ContextProvider>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route index element={<HomePage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/product" element={<ProductPage />} />
            <Route path="/addProduct" element={<AddProductPage />} />
            <Route path="/editProduct/:id" element={<EditProductPage />} />
            <Route path="/product/detail/:id" element={<ProductDetailPage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/category" element={<CategoryPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/profile/:id" element={<UserProfilePage />} />
            <Route path="/profile" element={<ViewProfilePage />} />
            <Route path="/editProfile" element={<EditProfilePage />} />
            <Route path="/changePassword" element={<ChangePasswordPage />} />
            <Route path="/sendEmail" element={<SendEmailPage />} />
            <Route path="/enterOTP" element={<EnterOtpPage />} />
            <Route path="/resetPassword" element={<ResetPasswordPage />} />
            <Route path="/notAuthorized" element={<NotAuthorizedPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </ContextProvider>
    </div>
  );
}

export default App;
