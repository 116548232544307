import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Form, Grid, Input, theme, Typography } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { Context } from "../../context/Contexts";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;
const API_KEY = process.env.REACT_APP_API_KEY;

export default function Login() {
  // axios.defaults.withCredentials = true;

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { token } = useToken();
  const screens = useBreakpoint();

  const navigate = useNavigate();
  const { setResetUserEmail } = useContext(Context);

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px",
      borderRadius: "7px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
    header: {
      marginBottom: token.marginXL,
      textAlign: "center",
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
    beteseriBgColor: {
      backgroundColor: "#ED6C02",
      marginBottom: "18px",
    },
  };

  const onFinish = (values) => {
    // let otp = Math.floor(Math.random() * 9000 + 1000);

    axios
      .post(`${API_KEY}/auth/checkEmail`, values)
      .then((response) => {
        if (response.status === 200) {
          setError(null);
          setSuccess(response.data.message);

          setResetUserEmail(values.email);

          // navigate("/enterOTP");
          navigate("/login");

          setSuccess(null);
        }
      })
      .catch((error) => {
        setSuccess(null);
        setError(error?.response?.data?.message);
      });
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        {/* header */}
        <div style={styles.header}>
          <Title style={styles.title}>Enter Email Address</Title>

          {error && !success && <Alert message={error} type="error" showIcon />}
          {success && !error && (
            <Alert message={success} type="success" showIcon />
          )}
          {!success && !error && (
            <Text style={styles.text}>
              Please enter your email address so that we can send you a
              verification code.
            </Text>
          )}
        </div>

        {/* form */}
        <Form
          name="send_email"
          onFinish={onFinish}
          layout="vertical"
          requiredMark="optional"
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Email address is required!",
              },
              {
                type: "email",
                message: "Please enter a valid email!",
              },
            ]}
            hasFeedback
          >
            <Input
              prefix={<MailOutlined />}
              placeholder="Enter your Email address here..."
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: "0px" }}>
            <Button
              block="true"
              type="primary"
              htmlType="submit"
              style={styles.beteseriBgColor}
            >
              Send Verification Code
            </Button>
          </Form.Item>
        </Form>
      </div>
    </section>
  );
}
