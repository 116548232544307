import axios from "axios";
import { createContext, useEffect, useState } from "react";

const API_KEY = process.env.REACT_APP_API_KEY;

const getDefaultCart = async () => {
  try {
    let products;
    const response = await axios.get(`${API_KEY}/product/getProducts`);
    if (response.status === 200) {
      products = response.data;
    }

    let cart = {};
    for (let i = 0; i < products.length; i++) {
      cart[products[i].id] = Number(0);
    }
    return cart;
  } catch (error) {
    console.error("Error fetching products:", error);
  }
};

export const Context = createContext(null);

export const ContextProvider = (props) => {
  //* STATES
  const [authState, setAuthState] = useState(false); //for checking is a user is logged in or not (authentication)
  const [authRole, setAuthRole] = useState(""); // for holding the signed in users role (for authorization)
  const [loggedInUsername, setLoggedInUsername] = useState("");
  const [cartItems, setCartItems] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMenuItem, setSelectedMenuItem] = useState("0");
  const [resetUserEmail, setResetUserEmail] = useState("");

  //* USE EFFECTS
  useEffect(() => {
    const fetchCart = async () => {
      const cart = await getDefaultCart();
      setCartItems(cart);
    };
    fetchCart();
  }, []);

  //* FUNCTIONS
  const addToCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: (prev[itemId] || 0) + 1 }));
  };

  const subtractFromCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: (prev[itemId] || 0) - 1 }));
  };

  const updateCartItemAmount = (itemId, newAmount) => {
    setCartItems((prev) => ({ ...prev, [itemId]: newAmount }));
  };

  const clearCart = () => {
    setCartItems({});
    // console.log(cartItems);
  };

  // things we want to pass
  const contextValue = {
    authState,
    setAuthState,
    authRole,
    setAuthRole,
    loggedInUsername,
    setLoggedInUsername,
    cartItems,
    addToCart,
    subtractFromCart,
    updateCartItemAmount,
    clearCart,
    searchTerm,
    setSearchTerm,
    selectedMenuItem,
    setSelectedMenuItem,
    resetUserEmail,
    setResetUserEmail,
  };

  return (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  );
};
