import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Grid, Input, theme, Typography, Alert } from "antd";
import { LockOutlined, UserOutlined, MailOutlined } from "@ant-design/icons";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;
const API_KEY = process.env.REACT_APP_API_KEY;

export default function Register() {
  const { token } = useToken();
  const screens = useBreakpoint();

  const primaryColor = "#ED6C02";
  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px",
      borderRadius: "7px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
    },
    header: {
      marginBottom: token.marginXL,
      textAlign: "center",
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
  };

  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const navigate = useNavigate();

  const onFinish = (values) => {
    setIsRegistering(true);
    axios
      .post(API_KEY + "/user/register", values)
      .then((response) => {
        if (response.status === 201) {
          setError(null);
          setSuccess(response.data.message + " Redirecting...");
          navigate("/login");
          setSuccess(null);
        }
      })
      .catch((error) => {
        setSuccess(null);
        setError(error.response.data.message);
      })
      .finally(() => {
        setIsRegistering(false);
      });
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        {/* header */}
        <div style={styles.header}>
          <Title style={styles.title}>Create Account</Title>
          {error && !success && <Alert message={error} type="error" showIcon />}
          {success && !error && (
            <Alert message={success} type="success" showIcon />
          )}
          {!success && !error && (
            <Text style={styles.text}>
              Create your account to start your journey.
            </Text>
          )}
        </div>

        {/* form */}
        <Form
          name="user_registration"
          initialValues={{}}
          onFinish={onFinish}
          layout="vertical"
          requiredMark="optional"
          autoComplete="off"
        >
          <Form.Item
            name="fullname"
            rules={[
              {
                required: true,
                message: "Full name is required!",
              },
              {
                validator: (_, value) =>
                  value &&
                  value.trim() && // Ensure non-empty string after trimming whitespaces
                  !/^\s*$/.test(value) && // Not only whitespaces
                  /^([^ ]+ [^ ]+)+$/.test(value) // Exactly one space between words
                    ? Promise.resolve()
                    : Promise.reject(
                        "Please enter your full name with only one space between names! (Example: John Doe)"
                      ),
              },
            ]}
            hasFeedback
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="Full Name (Example: John Doe)"
            />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Email address is required!",
              },
              {
                type: "email",
                message: "Please enter a valid email!",
              },
            ]}
            hasFeedback
          >
            <Input prefix={<MailOutlined />} placeholder="Email" />
          </Form.Item>

          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "User Name is required",
              },
              {
                validator: (_, value) =>
                  value &&
                  value.length >= 5 &&
                  value.length <= 15 &&
                  /(?=.*[a-z])|(?=.*[A-Z])/.test(value) &&
                  /(?=.*\d)|.(?=.*[-_!])/ && // Lookahead for either number OR special character
                  !/(^\d+$|^[-_!]+$)/.test(value)
                    ? Promise.resolve()
                    : Promise.reject(
                        "Username must be 5-15 characters, include letters (uppercase or lowercase), numbers or special characters (-_.!), but not only numbers or special characters!"
                      ),
              },
            ]}
            hasFeedback
          >
            <Input prefix={<UserOutlined />} placeholder="User Name" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Password is required!",
              },
              {
                validator: (_, value) =>
                  value &&
                  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]/.test(value) &&
                  value.length >= 8 &&
                  value.length <= 12
                    ? Promise.resolve()
                    : Promise.reject(
                        "Password must be 8 - 12 characters long and contain a lowercase letter, an uppercase letter, and a number!"
                      ),
              },
            ]}
            hasFeedback
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Confirm Password is required!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Passwords do not match!");
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Confirm Password"
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: "0px" }}>
            <Button
              block="true"
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: primaryColor }}
              disabled={isRegistering}
            >
              {isRegistering ? "Registering..." : "Register"}
            </Button>

            <div style={styles.footer}>
              <Text style={styles.text}>Already have an account?</Text>{" "}
              <Link
                onClick={() => navigate("/login")}
                style={{ color: primaryColor }}
              >
                Login now
              </Link>
            </div>
          </Form.Item>
        </Form>
      </div>
    </section>
  );
}
