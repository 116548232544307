import React from "react";
import { Divider, List } from "antd";

const App = ({ listTitle, data }) => (
  <>
    <Divider orientation="left">{listTitle}</Divider>
    <List
      size="large"
      bordered
      dataSource={data}
      renderItem={(item) => <List.Item>{item}</List.Item>}
      style={{ margin: "0px 10px" }}
    />
  </>
);
export default App;
